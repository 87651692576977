import { Routes, Route } from "react-router-dom";
import { Observation } from "./Observation";

const ObservationRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Observation/>}/>
            <Route path="/:plantId/*" element={<Observation/>}/>
        </Routes>
    );
};

export { ObservationRouter };
