import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { ProcessGraph } from "../monitor/ProcessGraph";
import icons from "../../enums/icons";
import types from "../../enums/types";
import { TextInput, TextArea, Icon, Button } from "@circle/kip-components";
import { useParams } from "react-router-dom";
import { toDateString } from "../../helper/helper";
import { ObservationGraph } from "../observation/ObservationGraph";
import { Heatmap } from "../Heatmap";

const EvaluationForm = ({ ...props }) => {
    const params = useParams();
    const { translate, currentLanguage } = useTranslation();
    const { trigger } = useTrigger();
    const { history, graph, observations, calendar } = useBranch({
        history:      ["sidebarHistory"],
        graph:        ["sidebarContent", "graph"],
        observations: ["observations"],
        calendar:     ["queryOptions", "calendar"]
    });

    const [graphType, setGraphType] = useState("default");

    const fromString = calendar.from.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");
    const toString   = calendar.until.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");

    useEffect(() => {
        const from          = calendar.from.getTime() / 1000;
        const until         = calendar.until.getTime() / 1000;
        const plant         = props.plant_id ?? location.pathname.split("?")[0].split("/").slice(-1)[0];
        const [sign, descr] = props.referenceObject.split(" | ");

        trigger("fetchGraph", {
            messageType:           props.messageType,
            system:                props.system,
            message:               props.message,
            messageEx:             props.messageEx,
            referenceObject_Descr: descr, // eslint-disable-line camelcase
            referenceObject_Sign:  sign // eslint-disable-line camelcase
        });

        const filters = {
            startTime:   `[${from},${until}]`,
            plant_id:    plant, // eslint-disable-line camelcase
            messageType: props.messageType,
            system:      props.system,
            message:     props.message,
            messageEx:   props.messageEx
        };

        if((until - from) < 86400) return;

        trigger("retrieve", "sidebar/heatmap", ["sidebarContent", "heatmap"], {
            ...filters,
            referenceObject: props.referenceObject
        });
    }, []);

    const onClick = e => {
        e.stopPropagation();

        trigger("toggleObservation", {
            id:              props.id,
            plant_id:        params.plantId || props.plant_id, // eslint-disable-line camelcase
            messageType:     props.messageType,
            system:          props.system,
            message:         props.message,
            messageEx:       props.messageEx,
            referenceObject: props.referenceObject
        });
    };

    const isActive = Boolean(observations.find(x =>
        x.plant_id === params.plantId &&
        x.messageType === props.messageType &&
        x.system === props.system &&
        x.message === props.message &&
        x.messageEx === props.messageEx &&
        x.referenceObject === props.referenceObject
    ));

    return (
        <div>
            <div className="form">
                <div className="flex">
                    <div className="flex-column flex-grow">
                        <span className="header font-bold">{ translate("sidebar.headline").toUpperCase() }</span>
                        <span className="font-bold">{ fromString } - { toString }</span>
                    </div>
                    <Button _variant="icon" onClick={onClick} _appearance={isActive ? "primary" : "default"}>
                        <Icon _icon={isActive ? "StarSolid" : "Star"}/>
                    </Button>
                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.messageType") }
                        </span>
                        <TextInput
                            className={`full-width input-message-${props.messageType}`}
                            iconPosition="start"
                            icon={icons[props.messageType]}
                            isReadonly
                            value={translate(types[props.messageType])}
                        />
                    </div>
                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.system") }
                        </span>
                        <TextInput
                            className="full-width"
                            isReadonly
                            value={props.system}
                        />
                    </div>

                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.text") }
                        </span>
                        <TextArea
                            className="full-width"
                            readOnly={true}
                            value={props.message}
                        />
                    </div>
                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.additional.text") }
                        </span>
                        <TextArea
                            className="full-width"
                            readOnly={true}
                            value={props.messageEx}
                        />
                    </div>
                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.refObj") }
                        </span>
                        <TextInput
                            className="full-width"
                            isReadonly
                            value={props.referenceObject}
                        />
                    </div>
                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.avg_duration") }
                        </span>
                        <TextInput
                            isReadonly
                            value={toDateString(props.duration)}
                        />
                    </div>
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.frequency") }
                        </span>
                        <TextInput
                            isReadonly
                            value={props.frequency}
                        />
                    </div>
                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.process") }
                        </span>
                        <ProcessGraph data={graph}/>
                    </div>
                </div>
                <div className="flex form-row">
                    <div className="form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.course") }
                        </span>
                        <div className="monitor-graph flex-column flex-grow">
                            <div className="horizontal-end">
                                <Button _appearance={graphType === "default" ? "primary" : "default"} _variant="icon" _inGroup onClick={() => setGraphType("default")}>
                                    <Icon _icon="Chart"/>
                                </Button>
                                <Button _appearance={graphType === "heatmap" ? "primary" : "default"} _variant="icon" _inGroup onClick={() => setGraphType("heatmap")}>
                                    <Icon _icon="Heatmap"/>
                                </Button>
                            </div>
                            {
                                graphType === "heatmap" &&
                                <Heatmap/>
                            }
                            {
                                graphType === "default" &&
                                <ObservationGraph
                                    active={{
                                        id:              props.id,
                                        messageType:     props.messageType,
                                        system:          props.system,
                                        message:         props.message,
                                        messageEx:       props.messageEx,
                                        referenceObject: props.referenceObject
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="flex form-row">
                    <div className="message-history form-column flex-column flex-grow">
                        <span className="form-header font-bold">
                            { translate("message.history") }
                        </span>
                        <div className="flex col-1 sidebar-box">
                            <div className="flex-column col-2">
                                <span className="clickable font-bold" onClick={() => trigger("orderHistoryBy", "startTime")}>{ translate("form.timerange") }</span>
                                {
                                    history.map((x, key) => (
                                        <span key={key}>{`${new Date(x.startTime).toLocaleDateString(currentLanguage, { timeZone: "UTC", day: "2-digit", month: "2-digit", year: "numeric" })} ${`0${new Date(x.startTime).getUTCHours()}`.slice(-2)} Uhr`}</span>
                                    ))
                                }
                            </div>
                            <div className="flex-column col-3">
                                <span className="clickable font-bold" onClick={() => trigger("orderHistoryBy", "avg")}>{ translate("form.average") }</span>
                                {
                                    history.map((x, key) => (
                                        <span key={key}>{`${`0${parseInt(x.avg / 60 / 60, 10)}`.slice(-2)}:${`0${parseInt((x.avg / 60) % 60, 10)}`.slice(-2)}:${`0${parseInt(x.avg % 60, 10)}`.slice(-2)}`}</span>
                                    ))
                                }
                            </div>
                            <div className="flex-column">
                                <span className="clickable font-bold" onClick={() => trigger("orderHistoryBy", "amount")}>{ translate("form.amount") }</span>
                                {
                                    history.map((x, key) => (
                                        <span key={key} className="align-right">{x.amount}</span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EvaluationForm.propTypes = {
    messageType:     PropTypes.string,
    system:          PropTypes.string,
    message:         PropTypes.string,
    messageEx:       PropTypes.string,
    referenceObject: PropTypes.string,
    duration:        PropTypes.string,
    frequency:       PropTypes.string,
    plant_id:        PropTypes.string, // eslint-disable-line camelcase
    id:              PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ])
};

export { EvaluationForm };
