import { useEffect } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { useBranch, useTrigger } from "@circle/gestalt-app";
import { Evaluation } from "./Evaluation";
import { isUuid } from "../../helper/helper";

const EvaluationRouter = () => {
    const params = useParams();
    const { trigger } = useTrigger();
    const { plants, selectedPlant } = useBranch({
        plants:        ["licensedPlants"],
        selectedPlant: ["selectedPlant"]
    });

    const plantId  = isUuid(params.plantId) ? params.plantId : selectedPlant?.id;
    const selected = plants.find(elem => elem.id === plantId);

    useEffect(() => {
        if(!params.plantId) trigger("onPlantSelect", selectedPlant?.id);
        trigger("onPlantSelect", params.plantId);
    }, []); //eslint-disable-line

    return (
        <Routes>
            <Route path="/" element={<Evaluation selected={selected}/>}/>
            <Route path="*" element={<Navigate to="/evaluation" replace/>}/>
        </Routes>
    );
};

export { EvaluationRouter };
