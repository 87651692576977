export default {
    errorDuration:   "error-icon",
    warningDuration: "warning-icon",
    infoDuration:    "info-icon",
    logDuration:     "log-icon",
    taskDuration:    "task-icon",
    systemDuration:  "system-icon",
    err:             "error-icon",
    warn:            "warning-icon",
    info:            "info-icon",
    log:             "log-icon",
    task:            "task-icon",
    system:          "system-icon",
    errors:          "error-icon",
    warnings:        "warning-icon",
    infos:           "info-icon",
    logs:            "log-icon",
    tasks:           "task-icon",
    systems:         "system-icon"
};
