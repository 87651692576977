import { useEffect } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { useBranch, useTrigger } from "@circle/gestalt-app";
import { isUuid } from "../../helper/helper";
import { CausesOverview } from "./CausesOverview.jsx";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";

const CausesOverviewRouter = () => {
    const params = useParams();
    const { trigger } = useTrigger();
    const { plants, selectedPlant, options } = useBranch({
        plants:          ["plants"],
        selectedPlant:   ["selectedPlant"],
        options:         ["queryOptions"],
        breadcrumbsPath: ["breadcrumbsPath"]
    });

    const plantId  = isUuid(params.plantId) ? params.plantId : selectedPlant?.id;
    const selected = plants.find(elem => elem.id === plantId);

    const [filter] = useQueryString({
        options:  options,
        plants:   plants,
        optional: {
            keyword: x => x ? JSON.parse(x) : []
        }
    });

    useQuery({
        options: filter,
        keys:    ["keyword"]
    });

    useEffect(() => {
        if(!params.plantId) trigger("onPlantSelect", selectedPlant?.id);
        trigger("onPlantSelect", params.plantId);
    }, []); //eslint-disable-line

    return (
        <Routes>
            <Route path="/" element={<CausesOverview selected={selected}/>}/>
            <Route path="*" element={<Navigate to="/causes" replace/>}/>
        </Routes>
    );
};

export { CausesOverviewRouter };
