import { useTranslation } from "@circle/gestalt-app";
import { Button } from "@circle/kip-components";
import PropTypes from "prop-types";

const SidebarButtons = ({ ...props }) => {
    const { translate } = useTranslation();

    const onFormViewChange = val => {
        props.onFormViewChange(val);
    };

    return (
        <div className="sidebarButtonForm">
            <Button _appearance="transparent" className={props.formVariant === "detailForm" && "primColor"} onClick={() => onFormViewChange("detailForm")}><span className="header font-bold">{ translate("monitor.form.analyses").toUpperCase() }</span></Button>
            <Button _appearance="transparent" className={props.formVariant === "causeReports" && "primColor"} onClick={() => onFormViewChange("causeReports")}><span className="header font-bold">{ translate("monitor.form.details").toUpperCase() }</span></Button>
        </div>
    );
};

SidebarButtons.propTypes = {
    formVariant:      PropTypes.string,
    onFormViewChange: PropTypes.func
};

export { SidebarButtons };
