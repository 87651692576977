import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Fragment } from "react";

const BreadcrumbItem = ({ ...props }) => {
    return (
        <Fragment>
            {
                props.path && props.clickable &&
                <NavLink to={props.path}>
                    { props.value }
                </NavLink>
            }
            {
                !props.clickable &&
                    props.value
            }
            {
                !props.path && props.clickable &&
                <div className={props.className} onClick={props.onClick}>
                    { props.value }
                </div>
            }
        </Fragment>

    );
};

BreadcrumbItem.propTypes = {
    clickable: PropTypes.bool,
    value:     PropTypes.string,
    path:      PropTypes.string,
    className: PropTypes.string,
    onClick:   PropTypes.func
};

export { BreadcrumbItem };
