import PropTypes from "prop-types";
import { Icon } from "@circle/kip-components";

const ListHeader = ({ ...props }) => (
    <div className="accordeon-item-row">
        <div className="flex vertical-align">
            {
                !props.disabled &&
                <Icon className="chevron" _icon="ChevronRight"/>
            }
            {
                props.disabled &&
                <div className="chevron-placeholder"/>
            }
            <span className="title flex-grow">{ props.name }</span>
        </div>
        <div className="horizontal-end vertical-align">
            <span>{ !isNaN(props.count) ? props.count : "-" }</span>
        </div>
        <div className="horizontal-end vertical-align">
            <span>{ !isNaN(props.duration) ? props.duration : "-" }</span>
        </div>
        <div className="horizontal-end vertical-align">
            <span>{ !isNaN(props.average) ? props.average : "-" }</span>
        </div>
        <div/>
    </div>
);

ListHeader.propTypes = {
    disabled: PropTypes.bool,
    name:     PropTypes.string,
    count:    PropTypes.number,
    duration: PropTypes.string,
    average:  PropTypes.string
};

export { ListHeader };
