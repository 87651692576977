import { useBranch, useTranslation } from "@circle/gestalt-app";
import { ResponsiveHeatMap } from "@nivo/heatmap";

const Heatmap = () => {
    const { translate } = useTranslation();
    const { data }      = useBranch({
        data: ["sidebarContent", "heatmap"]
    });
    const content  = data.map(x => ({ ...x, data: x.data.map(y => ({ ...y, x: y.x.includes(":") ? y.x : translate(`week.${y.x}`) })) }));
    const maxValue = content.reduce((dest, elem) => {
        const max = elem.data.reduce((all, x) => all < x.y ? x.y : all, 0);

        return dest < max ? max : dest;
    }, 0);

    return (
        <div className="full-width" style={{ height: 100 + data.length * 20 }}>
            <ResponsiveHeatMap
                data={content}
                margin={{ top: 60, right: 90, bottom: 90, left: 90 }}
                valueFormat=">-.2s"
                enableLabels={false}
                axisTop={null}
                isInteractive={true}
                axisBottom={{
                    tickSize:     5,
                    tickPadding:  5,
                    tickRotation: -90
                }}
                axisLeft={{
                    tickSize:     5,
                    tickPadding:  5,
                    tickRotation: 0
                }}
                colors={{
                    type:      "diverging",
                    scheme:    "orange_red",
                    divergeAt: 0.5,
                    minValue:  0,
                    maxValue:  maxValue
                }}
                emptyColor="#ffffff"
                legends={[
                    {
                        anchor:       "bottom",
                        translateX:   0,
                        translateY:   70,
                        length:       400,
                        thickness:    8,
                        direction:    "row",
                        tickPosition: "after",
                        tickSize:     3,
                        tickSpacing:  4,
                        tickOverlap:  false,
                        titleAlign:   "start",
                        titleOffset:  4
                    }
                ]}
            />
        </div>
    );
};

export { Heatmap };
