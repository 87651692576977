import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { msToString } from "../../helper/messageDeleteTimeSpans";
import { Card, CardHeader, Icon, OverflowContainer } from "@circle/kip-components";

import styles from "./settingsTile.module.scss";

const SettingsTile = props => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();
    const { settings, selectedPlants } = useBranch({
        settings:       ["message_delete_settings"],
        selectedPlants: ["settingsPlantsSelection"]
    });
    const myDeleteSettings = settings.find(x => x.plant_id === props.plant.id);

    return (
        <label className={styles.item}>
            <input
                type="checkbox"
                onChange={e => e.target.checked ? trigger("addPlantToSettingsSelection", props.plant.id) : trigger("removePlantFromSettingsSelection", props.plant.id)}
                checked={selectedPlants.includes(props.plant.id)}
            />

            <Card>
                <CardHeader className={styles.header}>
                    <img src={props.plant.image ?? "/images/Bitmap.png"} loading="lazy" alt="Manufacturer Logo" />
                    <div className={styles.titles}>
                        <h2>
                            {
                                (translate(props.plant.name) || "").length > 24 ?
                                    `${translate(props.plant.name).substring(0, 24)}...` :
                                    translate(props.plant.name)
                            }
                        </h2>
                        <h3>
                            {translate(props.plant.location)}
                        </h3>
                    </div>
                </CardHeader>

                <OverflowContainer className={styles.content}>
                    <span className={styles.label}>
                        <Icon _icon="Error" className="error-icon" />
                        {translate("settings.text.tile")}
                    </span>
                    <span className={styles.value}>{msToString(myDeleteSettings?.errAfterMS, true, translate)}</span>
                    <span className={styles.label}>
                        <Icon _icon="Task" className="task-icon" />
                        {translate("settings.text.tile")}
                    </span>
                    <span className={styles.value}>{msToString(myDeleteSettings?.taskAfterMS, true, translate)}</span>
                    <span className={styles.label}>
                        <Icon _icon="Warning" className="warning-icon" />
                        {translate("settings.text.tile")}
                    </span>
                    <span className={styles.value}>{msToString(myDeleteSettings?.warnAfterMS, true, translate)}</span>
                    <span className={styles.label}>
                        <Icon _icon="Info" className="info-icon" />
                        {translate("settings.text.tile")}
                    </span>
                    <span className={styles.value}>{msToString(myDeleteSettings?.infoAfterMS, true, translate)}</span>
                    <span className={styles.label}>
                        <Icon _icon="System" className="system-icon" />
                        {translate("settings.text.tile")}
                    </span>
                    <span className={styles.value}>{msToString(myDeleteSettings?.systemAfterMS, true, translate)}</span>
                    <span className={styles.label}>
                        <Icon _icon="Log" className="logs-icon" />
                        {translate("settings.text.tile")}
                    </span>
                    <span className={styles.value}>{msToString(myDeleteSettings?.logAfterMS, true, translate)}</span>
                </OverflowContainer>
            </Card>
        </label>
    );
};

SettingsTile.propTypes = {
    plant: PropTypes.object.isRequired
};

export { SettingsTile };
