import { useState } from "react";
import { Button, Icon } from "@circle/kip-components";
import PropTypes from "prop-types";
import { resolveClassNames, useOutsideClickHandler, getRandomString } from "palstek";

import styles from "./routinesButton.module.scss";
import { useTranslation } from "@circle/gestalt-app";

const RoutinesButton = ({ onSubmit = x => x, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const { translate } = useTranslation();
    const btnRefId = getRandomString(9);

    const outsideClickHandler = e => {
        if(!isOpen || e.composedPath().find(x => x.id === btnRefId)) return;

        setIsOpen(!isOpen);
    };
    const [ref] = useOutsideClickHandler(outsideClickHandler, isOpen);

    const onSelect = id => {
        setSelected(selected.includes(id) ? selected.filter(x => x !== id) : selected.concat(id));
    };

    const _onSubmit = () => {
        const valuesToSubmit = selected.map(x => ({ routine_id: props.routinesToMap?.find(y => y.id === x).id })); // eslint-disable-line camelcase

        onSubmit(valuesToSubmit);
        setIsOpen(false);
        setSelected([]);
    };

    const handleOpenClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.button}>
            <Button id={btnRefId} className={resolveClassNames(props.className, styles.button)} _variant="icon" onClick={handleOpenClick}>
                <Icon _icon="Add" />
            </Button>
            {
                isOpen &&
                <div ref={ref} className={styles.modal} onClick={e => e.stopPropagation()}>
                    <div className={styles.header}>
                        <span>{translate("routines.button.text")}</span>
                        <Button disabled={selected.length === 0} _variant="icon" _size="s" _appearance={"primary"} className={styles.submit} onClick={() => _onSubmit()}>
                            <Icon _icon="Check" />
                        </Button>
                    </div>
                    {
                        props.routinesToMap.length > 0 &&
                        <div className={styles.list}>
                            {
                                props.routinesToMap.map((x, key) => (
                                    <button key={key} className={resolveClassNames(styles.row)} onClick={() => onSelect(x.id)}>
                                        <Icon _icon="Check" className={resolveClassNames(styles.groupIcon, selected.includes(x.id) && styles.visible)} />
                                        <span>{translate(x.name)}</span>
                                    </button>
                                ))
                            }
                        </div>
                    }
                </div>
            }
        </div>
    );
};

RoutinesButton.propTypes = {
    className:     PropTypes.string,
    onSubmit:      PropTypes.func,
    offset:        PropTypes.object,
    isEnabled:     PropTypes.bool,
    routinesToMap: PropTypes.arrayOf(PropTypes.object)
};

export { RoutinesButton };
