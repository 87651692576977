import { useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useBranch, useTranslation } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";
import { Button, Icon } from "@circle/kip-components";
import styles from "./file-upload.module.scss";
import { FileViewer } from "../fileViewer";
import { File } from "../../../types/File";

const FileUpload = ({ onChange = x => x, className = "", accept = { "image/*": [] }, ...props }) => { // eslint-disable-line complexity
    const { translate }     = useTranslation();
    const { createRequest } = useBranch({
        createRequest: ["createRequest"]
    });
    const [progress, setProgress] = useState(-1);

    const onDrop = ([file]) => {
        if(!file) return null;

        const request = createRequest();

        request.on("upload", msg => {
            setProgress(-1);

            return onChange(msg);
        });
        request.on("progress", e => setProgress(Math.ceil(e.loaded / e.total * 100)));
        request.on("error", e => console.log("error", e));

        return request.fetch(file);
    };

    const handleDeleteClick = e => {
        e.stopPropagation();
        e.preventDefault();
        onChange(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept:   accept,
        disabled: props.readOnly,
        onDrop
    });

    return (
        <div className={resolveClassNames(styles.fileUpload, className)}>
            {
                (progress === -1 && !props.value) &&
                <div className={resolveClassNames(styles.fileWrapper, `${props.isFileNull ? styles.clickable : ""}`)} {...getRootProps()}>
                    <div className={styles.fileInputZone}>
                        <Icon className={styles.uploadIcon} _icon="Upload"/>
                        <b className={styles.uploadText}>
                            {translate("file.upload.description")}
                        </b>
                        <Button>
                            {translate("file.upload.action") }
                        </Button>
                    </div>
                    <input {...getInputProps()} />
                </div>
            }
            {
                props.value &&
                <div className={styles.fileContent}>
                    <div className={styles.filWrapper}>
                        <FileViewer file={new File({ url: props.value })}/>
                    </div>
                    <div className={styles.deleteButton}>
                        {
                            !props.readOnly &&
                            <Button _variant="icon" onClick={handleDeleteClick} >
                                <Icon _icon="Trashcan" />
                            </Button>
                        }
                    </div>
                </div>
            }
            {
                progress > -1 && progress < 100 &&
                <div className={resolveClassNames(styles.filWrapper, styles.fileContent)}>
                    <div className={styles.progress}>
                        <div className={styles.center} style={{ width: `${progress}%` }}/>
                    </div>
                    <span>{ progress }%</span>
                </div>
            }
        </div>
    );
};

FileUpload.propTypes = {
    value:         PropTypes.string,
    readOnly:      PropTypes.bool,
    isFileNull:    PropTypes.bool,
    placeholder:   PropTypes.string,
    className:     PropTypes.string,
    getRootProps:  PropTypes.func,
    getInputProps: PropTypes.func,
    accept:        PropTypes.object,
    onChange:      PropTypes.func
};

export { FileUpload };
