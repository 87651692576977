import PropTypes from "prop-types";

const Tick = ({ ...props }) => {
    const [sign, descr] = `${props.payload.value}`.split("\n");
    const signShort     = sign?.length < 50 ? sign : `${sign?.slice(0, 50)}...`;
    const descrShort    = descr?.length < 50 ? descr : `${descr?.slice(0, 50)}...`;

    return (
        <g transform={`translate(${0},${props.y})`}>
            <text x={0} y={0} dy={0} fill="#666" className={props.index % 2 === 0 ? "font-bold" : ""}>
                <tspan textAnchor="start" x="0">{ signShort }</tspan>
                {
                    descr &&
                    <tspan textAnchor="start" x="0" dy="20">{ descrShort }</tspan>
                }
            </text>
        </g>
    );
};

Tick.propTypes = {
    y:       PropTypes.number,
    payload: PropTypes.object,
    index:   PropTypes.number
};

export { Tick };
