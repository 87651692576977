import { useState, useRef, useLayoutEffect } from "react";
import { Button, Icon, TextInput } from "@circle/kip-components";
import PropTypes from "prop-types";
import { resolveClassNames, useOutsideClickHandler, getRandomString } from "palstek";
import { v4 as uuid } from "uuid";
import { useTranslation } from "@circle/gestalt-app";

import styles from "./groupbutton.module.scss";

const GroupButton = ({ onSubmit = x => x, ...props }) => { // eslint-disable-line max-statements
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [values, setValues] = useState([]);
    const [text, setText] = useState("");
    const { translate } = useTranslation();
    const btnRefId = getRandomString(9);
    const listRef = useRef(null);

    const outsideClickHandler = e => {
        if(!isOpen || e.composedPath().find(x => x.id === btnRefId)) return;

        setIsOpen(!isOpen);
        setSelected([]);
        setValues([]);
    };
    const [ref] = useOutsideClickHandler(outsideClickHandler, isOpen);

    const onSelect = id => {
        setSelected(selected.includes(id) ? selected.filter(x => x !== id) : selected.concat(id));
    };

    const addNewGroup = () => {
        if(props.groups.includes(text)) return;

        const id = uuid();

        setSelected(selected.concat(id));
        setValues(values.concat({
            id:   id,
            name: text
        }));
        setText("");
    };

    const handleAddGroupClick = () => {
        addNewGroup();
    };

    const onCreate = e => {
        if(e.keyCode !== 13) return;

        addNewGroup();
    };

    const _onSubmit = () => {
        onSubmit(selected.map(x => props.value.concat(values).find(y => y.id === x)));
        setIsOpen(false);
        setSelected([]);
        setValues([]);
    };

    const onChange = e => {
        setText(e.target.value);
    };

    const handleOpenClick = () => {
        setIsOpen(!isOpen);
    };

    useLayoutEffect(() => {
        if(!listRef?.current) return;

        listRef.current.scrollBy({
            top:      listRef.current.scrollHeight,
            behavior: "smooth"
        });
    }, [values]);

    return (
        <div className={styles.button} >
            <Button id={btnRefId} className={resolveClassNames(props.className, styles.button)} _variant="icon" onClick={handleOpenClick}>
                <Icon _icon="Add"/>
            </Button>
            {isOpen &&
                <div ref={ref} className={styles.modal} onClick={e => e.stopPropagation()}>
                    <div className={styles.header}>
                        <span>{ translate("group.button.text")}</span>
                        <Button disabled={selected.length === 0} _variant="icon" _appearance="primary" className={styles.submit} onClick={() => _onSubmit()}>
                            <Icon _icon="Check"/>
                        </Button>
                    </div>
                    <div className={resolveClassNames(styles.input)} onKeyDown={onCreate}>
                        <TextInput value={text} placeholder={translate("group.button.create")} onChange={onChange}>
                            {
                                text.length > 0 &&
                                <button className={styles.addGroupButton} onClick={handleAddGroupClick}>
                                    <Icon _icon="Check" />
                                </button>
                            }
                        </TextInput>
                    </div>
                    <div className={styles.list} ref={listRef}>
                        {
                            props.value.concat(values).map((x, key) => (
                                <button
                                    key={key}
                                    className={resolveClassNames(styles.row)}
                                    onClick={() => onSelect(x.id)}
                                >
                                    <Icon _icon="Check" className={resolveClassNames(styles.groupIcon, selected.includes(x.id) && styles.visible)}/>
                                    <span>{ x.name }</span>
                                </button>
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    );
};

GroupButton.propTypes = {
    groups:    PropTypes.arrayOf(PropTypes.string),
    value:     PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    onSubmit:  PropTypes.func
};

export { GroupButton };
