import { useState } from "react";
import { Icon, SwitchButton, Button, ButtonGroup, Modal } from "@circle/kip-components";
import { useTranslation, useTrigger } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import styles from "./rangeSelector.module.scss";
import { fromFakeUTC, toFakeUTC } from "../../../helper/helper";
import { DateTimePicker } from "../dateTimePicker/DateTimePicker";
import { DatePicker } from "../../../types/DatePicker";
import { useLayoutEffect } from "react";

const RangeSelector = ({ onTimeframeChange = x => x, ...props }) => { // eslint-disable-line complexity
    const { translate, currentLanguage } = useTranslation();
    const [isCalendarOpened, setIsCalendarOpened] = useState(false);
    const [isTimeFrameSelect, setIsTimeFrameSelect] = useState(false);
    const { trigger }    = useTrigger();

    const onCalendarClose = () => {
        setIsCalendarOpened(false);
    };

    const onCalendarSubmit = val => {
        setIsCalendarOpened(false);
        props.onChange(DatePicker.of([fromFakeUTC(val.from.getTime()), fromFakeUTC(val.until.getTime())]));
    };

    const hasWorkingShifts = props.workingShifts ? props.workingShifts?.length !== 0 : false;
    const onSelect = e => {
        const getDate = value => {
            if(value === "shift")     return DatePicker.of("today");
            if(value === "last7Days") return DatePicker.of("last7Days");
            if(value === "today")     return DatePicker.of("today");
            if(value === "yesterday") return DatePicker.of("yesterday");
            if(value === "month")     return DatePicker.of("thisMonth");
            if(value === "year")      return DatePicker.of("thisYear");

            return DatePicker.of("yesterday");
        };

        const getName = value => {
            return value === "shift" ? "shift" : value;
        };

        props.onChange({
            ...getDate(e.target.value),
            name: getName(e.target.value)
        });
    };

    const onTimeframeSelect = val => {
        onTimeframeChange(val);
    };

    const options = !props.isOverview && hasWorkingShifts ? [
        {
            value:   "year",
            checked: props.range === "year",
            label:   translate("overview.selector.year")
        },
        {
            value:   "month",
            checked: props.range === "month",
            label:   translate("overview.selector.month")
        },
        {
            value:   "last7Days",
            checked: props.range === "last7Days",
            label:   translate("overview.selector.last7Days")
        },
        {
            value:   "yesterday",
            checked: props.range === "yesterday",
            label:   translate("overview.selector.yesterday")
        },
        {
            value:   "today",
            checked: props.range === "today",
            label:   translate("overview.selector.today")
        },
        {
            value:   "shift",
            checked: props.range === "shift",
            label:   translate("overview.selector.shift")
        },
        {
            value:   "lastShift",
            checked: props.range === "lastShift",
            label:   translate("overview.selector.last.shift")
        }
    ] : [
        {
            value:   "year",
            checked: props.range === "year",
            label:   translate("overview.selector.year")
        },
        {
            value:   "month",
            checked: props.range === "month",
            label:   translate("overview.selector.month")
        },
        {
            value:   "last7Days",
            checked: props.range === "last7Days",
            label:   translate("overview.selector.last7Days")
        },
        {
            value:   "yesterday",
            checked: props.range === "yesterday",
            label:   translate("overview.selector.yesterday")
        },
        {
            value:   "today",
            checked: props.range === "today",
            label:   translate("overview.selector.today")
        }
    ];

    useLayoutEffect(() => {
        if(hasWorkingShifts && (props.range === "today" || props.range === "yesterday" || (props.selected.until - props.selected.from) <= 86400000)) {
            setIsTimeFrameSelect(true);
            return;
        }

        trigger("applyOption", "timeframes", "all");
        setIsTimeFrameSelect(false);
        return;
    }, [props.range, props.selected]);

    return (
        <div className={styles.selector}>
            <ButtonGroup>
                <Button _variant="icon" onClick={() => setIsCalendarOpened(true)}>
                    <Icon _icon="DatePicker" />
                </Button>
            </ButtonGroup>
            <div className={styles.switchButtons}>
                <SwitchButton
                    onChange={onSelect}
                    options={options}
                />
                <div className={styles.selectorButtonSection}>
                    { (hasWorkingShifts && !props.isOverview && !props.isObservation && isTimeFrameSelect && ((props.selected.until - props.selected.from) <= 86400000) && (props.range !== "lastShift" && props.range !== "shift")) &&
                    <>
                        <Button className={styles.selectorButton} onClick={() => onTimeframeSelect("shifts")} _appearance={props.timeframes === "shifts" ? "primary" : "default"}>{translate("action.filter.shift")}</Button>
                        <Button className={styles.selectorButton} onClick={() => onTimeframeSelect("all")} _appearance={props.timeframes === "all" ? "primary" : "default"}>{translate("action.filter.all.time")}</Button>
                    </>
                    }
                </div>
            </div>
            <Modal
                title={translate("modal.date")}
                isOpen={isCalendarOpened}
                isCloseable={false}
                stopBackgroundPropagation={false}
            >
                <DateTimePicker
                    onChange={onCalendarSubmit}
                    onCancel={onCalendarClose}
                    locale={currentLanguage}
                    value={{
                        from:  toFakeUTC(props.selected.from),
                        until: toFakeUTC(props.selected.until)
                    }}
                />
            </Modal>
        </div>
    );
};

RangeSelector.propTypes = {
    onChange:          PropTypes.func,
    range:             PropTypes.string,
    selected:          PropTypes.object,
    isOverview:        PropTypes.bool,
    isObservation:     PropTypes.bool,
    timeframes:        PropTypes.string,
    onTimeframeChange: PropTypes.func,
    workingShifts:     PropTypes.array
};

export { RangeSelector };
