import { orderBy } from "lodash";

const isUuid = (value: string) => {
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value);
};

const toFakeUTC = (input: string) => {
    const utcDate = new Date(input);

    return new Date(new Date(new Date(new Date(new Date().setFullYear(utcDate.getUTCFullYear())).setMonth(utcDate.getUTCMonth())).setDate(utcDate.getUTCDate())).setHours(utcDate.getUTCHours(), utcDate.getUTCMinutes(), utcDate.getUTCSeconds(), utcDate.getUTCMilliseconds()));
};

const fromFakeUTC = (input: string) => {
    const utcDate = new Date(input);

    return new Date(new Date(new Date(new Date(new Date().setUTCFullYear(utcDate.getFullYear())).setUTCMonth(utcDate.getMonth())).setUTCDate(utcDate.getDate())).setUTCHours(utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds(), utcDate.getMilliseconds()));
};

const toDateString = (value: number) => {
    const hours   = Math.floor(value / 60 / 60);
    const minutes = Math.floor(value / 60) % 60;
    const seconds = Math.floor(value % 60);

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")} h`;
};

interface Data {
    [key: string]: string;
}

type Callback = (arg1: string) => string;

const sortBy = (values: object, key: string, order: "asc" | "desc") => {
    return orderBy(values, [(x: Data) => x[key]?.toLowerCase()], [order]);
};

const sortAsc = (items: Data[], key: string, retrievalFunc: Callback = (x: any) => x) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const returnValues = items.concat([]);

    returnValues.sort((a, b) => {
        const valA = retrievalFunc(a[key])?.toLowerCase();
        const valB = retrievalFunc(b[key])?.toLowerCase();

        if(valA > valB) return 1;
        if(valA < valB) return -1;

        return 0;
    });

    return returnValues;
};

const toMinuteString = (value = 0) => {
    return `${Math.floor(value / 60)}:${ `0${Math.floor(value % 60)}`.slice(-2)} min`;
};

export {
    isUuid,
    toDateString,
    toFakeUTC,
    fromFakeUTC,
    sortBy,
    sortAsc,
    toMinuteString
};
