import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { CauseModal } from "./CauseModal";
import { Accordion } from "./CauseAccordion";
import { Modal } from "@circle/kip-components";
import { CauseForm } from "./CauseForm";
import { toWeekdayDate } from "../../helper/formatTimeSpan";
import { GroupButton } from "../GroupButton";
import { RoutinesButton } from "../RoutinesButton";
import styles from "./causeReports.module.scss";
import { resolveClassNames } from "palstek";

const CauseReportsForm = ({ ...props }) => { // eslint-disable-line max-statements,complexity
    const { translate, currentLanguage } = useTranslation();
    const { trigger }   = useTrigger();

    const [modal, setModal] = useState(false);
    const [cause, setCause] = useState({});
    const { plantCauses, groups, allGroups, routines, routinesMessages }   = useBranch({
        plantCauses:      ["causesByPlant"],
        groups:           ["groupsOfMessage"],
        allGroups:        ["groups"],
        routines:         ["routinesOfPlant"],
        routinesMessages: ["routines_messages_connections"]
    });

    const routinesConnections = routinesMessages?.filter(x => x.hash === props.message.hash).map(x => x.routines)?.flat()?.map(x => x.routine_id);
    const routinesOfMessage   = routines?.filter(x => routinesConnections.includes(x.id));
    const routinesToMap       = routines?.filter(routine => !routinesOfMessage.some(rM => rM.id === routine.id));

    const reports         = (plantCauses ?? []).concat([]);
    const includedMessage = props.isOverview ?
        reports.filter(x => (
            x.messages.find(y => (y.messageEx ?? "") === props.message?.messageEx &&
            (y.message ?? "") === props.message.message &&
            (y.system ?? "") === props.message.system &&
            (`${y.referenceObject_Sign} | ${y.referenceObject_Descr}`) === props.message.referenceObject &&
            (y.messageType ?? "") === props.message.messageType
            ))) :
        reports.filter(x => (
            x.messages.find(y => (y.messageEx ?? "") === props.message?.additionalText && // eslint-disable-line complexity
            (y.message ?? "") === props.message.message &&
            (y.system ?? "") === props.message.system &&
            (y.referenceObject_Descr ?? "") === props.message.refObjDescr &&
            (y.referenceObject_Sign ?? "") === props.message.refObjSign &&
            (y.messageType ?? "") === props.message.type
            )
        ));

    const output = includedMessage.map(report => ({
        ...report,
        group: includedMessage
            .reduce((dest, elem) => {
                const index = dest.findIndex(x => (
                    elem.name === x.name &&
                    toWeekdayDate(new Date(x?.startTime), currentLanguage) === toWeekdayDate(new Date(elem?.startTime), currentLanguage)
                ));

                if(report.name !== elem.name) return dest;
                if(index < 0)                 return dest.concat({ ...elem, count: 1 });

                dest.splice(index, 1, { ...dest[index], count: dest[index].count + 1 });

                return dest;
            }, [])
            .sort((x, y) => new Date(x.startTime) - new Date(y.startTime))
    }));

    const causes = Array.from(new Set(output.map(x => x.name)))
        .map(name => output.find(x => x.name === name));

    const onCloseModal = () => {
        setCause({});
        setModal(false);
    };

    const onDescription = elem => {
        setModal(true);
        setCause(elem);
    };

    const fetchGroups = () => {
        trigger("fetchGroupsOfMessage", props.message?.hash, props.message?.plant_id);
    };

    const onSubmitGroups = messageGroups => {
        const mGroups = messageGroups.map(y => y.name);
        const message = {
            plant_id:              props.selectedPlant?.id, //eslint-disable-line
            referenceObject_Sign:  props.message?.refObjSign, //eslint-disable-line
            referenceObject_Descr: props.message?.refObjDescr, //eslint-disable-line
            system:                props.message?.system,
            message:               props.message?.message,
            messageType:           props.message?.type
        };


        const groupsToApply = mGroups.map(group => ({
            ...message,
            name: group
        }));

        trigger("applyGroupingMessage", groupsToApply);
    };

    const onRoutinesSubmit = messageRoutines => {
        const objectToSend = {
            hash:     props.message.hash,
            routines: messageRoutines
        };

        trigger("applyRoutinesMessage", objectToSend);
    };

    useEffect(() => {
        fetchGroups();
    }, [props.message]);

    return (
        <div className="flex form-row">
            <div className="form-column flex-column flex-grow mb-5 flex-item">
                <span className="form-header font-bold">
                    { translate("message.additional.text") }
                </span>
                <div className="flex-item">
                    <span className={styles.valueItem}>
                        {props.isOverview ? props.message?.messageEx : props.message?.additionalText}
                    </span>
                    <span className="causeReportHeader flex-item">
                        <span className="form-header font-bold">
                            { translate("sidebar.cause.headline") }
                        </span>
                        <CauseModal
                            isSmall={true}
                            isIconView={true}
                            selectedPlant={props.selectedPlant}
                            selectedMessage={props.selectedMessage}
                        />
                    </span>
                    <span>
                        {
                            causes?.map((causeEl, key) =>
                                <Accordion
                                    key={key}
                                    name={causeEl?.name}
                                    selectedPlant={props?.selectedPlant}
                                    items={causeEl?.group}
                                    locale={currentLanguage}
                                    onClick={onDescription.bind(this, causeEl)}
                                />
                            )
                        }
                    </span>
                    <div className={resolveClassNames("causeReportHeader flex-item", styles.groupSection)}>
                        <span className="form-header font-bold">
                            { translate("sidebar.groups.headline") }
                        </span>{
                            <GroupButton
                                groups={groups.map(x => x.name)}
                                value={allGroups.filter(group => !groups.find(elem => group.name === elem.name))}
                                target={props.target.current}
                                onSubmit={onSubmitGroups}
                                className="smallButton"
                            />
                        }
                    </div>
                    <ul className={styles.valueList}>
                        {groups?.map((x, key) =>
                            <li key={key}>
                                {x?.name}
                            </li>
                        )}
                    </ul>

                    <div className="causeReportHeader flex-item">
                        <span className="form-header font-bold">
                            { translate("sidebar.routines.headline") }
                        </span>
                        <RoutinesButton target={props.target.current} routinesToMap={routinesToMap} onSubmit={onRoutinesSubmit} className="smallButton" />
                    </div>
                    <ul className={styles.valueList}>
                        {routinesOfMessage.map(x =>
                            <li key={x?.id}>
                                {translate(x?.name)}
                            </li>
                        )}
                    </ul>
                    <Modal
                        title={translate("overview.report.view")}
                        isOpen={modal}
                        isCloseable={false}
                        onClose={() => setModal(false)}
                        className="cause-modal"
                    >
                        <CauseForm
                            isEditable={false}
                            data={cause}
                            selectedPlant={props.selectedPlant}
                            onClose={onCloseModal}
                        />
                    </Modal>
                </div>
            </div>
        </div>
    );
};

CauseReportsForm.propTypes = {
    selectedPlant:   PropTypes.object,
    selectedMessage: PropTypes.object,
    message:         PropTypes.object,
    target:          PropTypes.any,
    isOverview:      PropTypes.bool
};

export { CauseReportsForm };
