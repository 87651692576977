import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { CauseModal } from "./CauseModal";
import { Modal, TextInput } from "@circle/kip-components";
import styles from "./causeOverview.module.scss";
import { Datatable } from "../generic/Datatable";
import { Column } from "../generic/Column";
import { CauseForm } from "./CauseForm";
import { search } from "../../helper/search";
import { sort } from "../../actions/files";
import { Breadcrumb } from "../Breadcrumb";
import { resolveClassNames } from "palstek";

const CausesOverview = ({ ...props }) => {
    const [modal, setModal] = useState(false);
    const [cause, setCause] = useState({});
    const { trigger }       = useTrigger();
    const { translate, currentLanguage } = useTranslation();
    const { plantCauses, selectedPlant, searchData, sortingSettings, breadcrumbsPath } = useBranch({
        plantCauses:          ["causesByPlant"],
        selectedPlant:        ["selectedPlant"],
        sortingSettings:      ["sortingSettings"],
        joinCausesCategories: ["joinCausesCategories"],
        searchData:           ["search"],
        filter:               ["filter"],
        breadcrumbsPath:      ["breadcrumbsPath"]
    });
    const onCloseModal = () => {
        setCause({});
        setModal(false);
    };
    const onDetails = (elem, e) => {
        e.preventDefault();
        setModal(true);
        setCause(elem);
    };

    const onHeadlineClick = x => {
        trigger("onSorting", x);
    };


    const mapped = search((plantCauses || [])
    .map(x => ({
        value: `${x.name}`,
        key:   x.id
    })), searchData.value).map(x => plantCauses?.find(ca => ca.id === x.key));
    const allCauses = sort({ items: mapped, property: sortingSettings.selected, ordering: sortingSettings.order, getter: translate });

    useEffect(() => {
        trigger("setFilter", {
            order:    "desc",
            selected: "startTime"
        });
    }, []);

    useEffect(() => {
        trigger("applyPlant", "");
    }, []);

    return (
        <>
            <div className={resolveClassNames("monitor flex-column", styles.container)}>
                <div className="monitor-main space-between">
                    <div>
                        <div className={styles.breadcrumbContainer}>
                            <Breadcrumb items={breadcrumbsPath} />
                        </div>
                        <div className={styles.plant}>
                            <div className={styles.logo}>
                                <img src={selectedPlant?.manufacturer?.logo ?? "/images/Bitmap.png"}/>
                            </div>
                            <div className={styles.info}>
                                <div className={styles.plantName}>
                                    <span>{ translate(selectedPlant?.name) }</span>
                                </div>
                                <span className={styles.manufacturer}>{ selectedPlant?.manufacturer?.name }</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row-reverse">
                        <CauseModal isIconView={true} selectedPlant={props.selected}/>
                    </div>
                </div>
                <div className="monitor-main space-between">
                    <TextInput
                        onChange={e => trigger("search", e.target.value)}
                        placeholder={translate("actions.search")}
                        icon="Search"
                        className={styles.search}
                        value={searchData.value}
                    />
                </div>
                <Datatable
                    onHeadlineClick={onHeadlineClick}
                    className="cause"
                    content={props.selected ? allCauses : []}
                    onRowClick={onDetails}
                    hasDrawerColumn={true}
                    onDetails={onDetails}
                    refs={["id", "name", "startTime", "endTime", "category", "message", "routine"]}
                    sortingSettings={sortingSettings}
                >
                    <Column
                        sortable
                        title="cause.name"
                        item="name"
                        width="16%"
                    />
                    <Column
                        sortable
                        title="cause.startTime"
                        item="startTime"
                        width="13%"
                        render={x => {
                            const dateStart = new Date(x);

                            return dateStart.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");
                        }}
                    />
                    <Column
                        sortable
                        title="cause.endTime"
                        item="endTime"
                        width="13%"
                        render={x => {
                            const dateStart = new Date(x);

                            return dateStart.toLocaleString(currentLanguage, { timeZone: "UTC" }).replace(",", "");
                        }}
                    />
                    <Column
                        title="cause.category"
                        item="categories"
                        width="20%"
                        render={x => {
                            const result = x?.map(y => y.name)?.toString();

                            return result;
                        }}
                    />
                    <Column
                        title="cause.messages"
                        item="messages"
                        width="12%"
                        render={x =>
                            x.length > 0 ? (<span>{translate("cause.linked.yes")}</span>) :
                                (<span>{translate("cause.linked.no")}</span>)
                        }
                    />
                    <Column
                        title="cause.routine"
                        item="routines"
                        width="12%"
                        render={x =>
                            x.length > 0 ? (<span>{translate("cause.linked.yes")}</span>) :
                                (<span>{translate("cause.linked.no")}</span>)
                        }
                    />
                </Datatable>
            </div>
            <Modal
                title={translate("overview.report.view")}
                isOpen={modal}
                isCloseable={false}
                onClose={() => setModal(false)}
                className="cause-modal"
            >
                <CauseForm
                    isEditable={false}
                    data={cause}
                    selectedPlant={selectedPlant}
                    onClose={onCloseModal}
                />
            </Modal>
        </>
    );
};

CausesOverview.propTypes = {
    selected: PropTypes.object
};

export { CausesOverview };
