const getDate    = () => `${new Date().toString().split(" ")[0] } ${ (`0${ new Date().getDate()}`).slice(-2)}.${ (`0${ new Date().getMonth() + 1}`).slice(-2)}.${new Date().getFullYear()}`;
const getTime    = () => `${ (`0${ new Date().getHours()}`).slice(-2)}:${ (`0${ new Date().getMinutes()}`).slice(-2)}:${ (`0${ new Date().getSeconds()}`).slice(-2)}`;
const getSeconds = (value: string, locale: string) => {
    if(locale !== "de") return `${value}s`;

    return `${value}s`.replace(".", ",");
};

const getDurationString = (milliseconds: number) => {
    const date    = new Date(milliseconds);
    const hours   = date.getUTCMonth() * 24 * 30 + (date.getUTCDate() - 1) * 24 + date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    return `${hours}h ${minutes}m ${seconds}s`;
};

const unifyDateInput = (date: number): Date => {
    const coeff = 1000 * 60;
    const res = new Date(Math.floor(date / coeff) * coeff);

    return res.getDate() !== new Date(date).getDate() ? new Date(res.getTime() - 1) : res;
};

const calcHourOffset = (locale: string, _date: Date, i: number) => {
    const date = new Date(_date);
    const res  = new Date(date.setUTCHours(i, date.getUTCMinutes(), 0, 0));

    return {
        raw:       res,
        formatted: res.toLocaleString(locale, { hour: "numeric", timeZone: "UTC" }),
        formatter: (x: Date) => x.toLocaleString(locale, { hour: "numeric", timeZone: "UTC" })
    };
};

const calcMinuteOffset = (locale: string, _date: Date, i: number, modulo = 5) => {
    const minuteMultiplier = 60 * 1000;

    const date = new Date(_date);
    const res  = new Date(date.setTime(date.getTime() + i * minuteMultiplier * modulo));

    return {
        raw:       res,
        formatted: res.toLocaleString(locale, { minute: "2-digit", timeZone: "UTC" }),
        formatter: (x: Date) => x.toLocaleString(locale, { minute: "2-digit", timeZone: "UTC" })
    };
};

const getHourOptions = (from: number, locale: string) => new Array(24).fill(null).map((_v, i) => {
    const x = calcHourOffset(locale, unifyDateInput(from), i);

    return ({
        value:   x.raw.toUTCString(),
        label:   x.formatted,
        checked: unifyDateInput(from).toUTCString() === x.raw.toUTCString()
    });
});

const getMinuteOptions = (from: number, locale: string) => new Array(12).fill(null).map((_v, i) => {
    const x = calcMinuteOffset(locale, unifyDateInput(from), i);

    return ({
        value:   x.raw.toUTCString(),
        label:   x.formatted,
        checked: unifyDateInput(from).toUTCString() === x.raw.toUTCString()
    });
});

const dateInputFormat = (date: string) => {
    const dateValue = date.split(".");

    const year = dateValue[2];
    const month = dateValue[1]?.length === 2 ? dateValue[1] : `0${dateValue[1]}`;
    const day = dateValue[0]?.length === 2 ? dateValue[0] : `0${dateValue[0]}`;

    return `${year}-${month}-${day}`;
};

const getRangeString = (date: Date[], locale: string) => { // eslint-disable-line complexity
    const from  = date[0];
    const until = date[1];

    const preparedUntil = new Date(new Date(new Date(until.getTime()).setFullYear(until.getUTCFullYear())).setMonth(until.getUTCMonth()));
    const preparedFrom  = new Date(new Date(new Date(from.getTime()).setFullYear(from.getUTCFullYear())).setMonth(from.getUTCMonth()));

    const showTime = from.getUTCHours() !== 0 || from.getUTCMinutes() !== 0 || !(until.getUTCHours() === 23 && until.getUTCMinutes() === 59);

    const isSameDay   = until.getUTCDate() - from.getUTCDate() === 0;
    const isSameMonth = until.getUTCMonth() - from.getUTCMonth() === 0;
    const isSameYear  = until.getFullYear() - from.getFullYear() === 0;
    const dateString  = `${until.getUTCDate()}. ${preparedUntil.toLocaleString(locale, { month: "long" })} ${until.getUTCFullYear()}${showTime ? `, ${isSameDay ? `${from.getUTCHours().toString().padStart(2, "0")}:${from.getUTCMinutes().toString().padStart(2, "0")} - ` : ""}${until.getUTCHours().toString().padStart(2, "0")}:${until.getUTCMinutes().toString().padStart(2, "0")}` : ""}`;

    if(isSameDay) return dateString;

    return `${from.getUTCDate()}.${!isSameMonth || showTime ? ` ${preparedFrom.toLocaleString(locale, { month: "long" })}` : ""}${!isSameYear ? ` ${from.getUTCFullYear()}` : ""}${showTime ? `, ${from.getUTCHours().toString().padStart(2, "0")}:${from.getUTCMinutes().toString().padStart(2, "0")}` : ""} - ${dateString}`;
};

export {
    getDate,
    getTime,
    getSeconds,
    getDurationString,
    getHourOptions,
    getMinuteOptions,
    unifyDateInput,
    dateInputFormat,
    getRangeString
};
