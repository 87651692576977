import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { usePrevious } from "../../hooks/previous";

const InfiniteList = ({ // eslint-disable-line complexity
    className = "",
    onFetch = x => x,
    element = "div",
    loader = () => <div>Lalilu</div>,
    placeholder = <div/>,
    metadata = { limit: 10, count: -1, page: 1 },
    ...props
}) => {
    const ref       = useRef();
    const wrapper   = useRef();
    const prevCount = usePrevious(props.children.length);

    const [blocked, setBlocked] = useState(false);

    const hasMore = props.children.length > 0 && (metadata.limit * metadata.page) < metadata.count && !blocked;
    const Element = typeof element === "string" || element instanceof String ? element : element.type;
    const _loader = loader();

    const isInViewport = () => {
        const wrapperRect = wrapper.current.getBoundingClientRect();

        if(wrapper.current.scrollHeight === wrapperRect.height) return true;
        if(!ref.current)                                        return null;

        const rect = ref.current.getBoundingClientRect();

        return wrapperRect.top + wrapperRect.height > rect.top;
    };

    const handleFetch = () => {
        if(blocked || !isInViewport()) return null;

        setBlocked(true);
        return onFetch();
    };

    const scrollTop = () => {
        wrapper.current.scrollTop = 0;
    };

    useEffect(() => {
        const cb = e => handleFetch(e);

        wrapper.current.addEventListener("resize", cb);
        wrapper.current.addEventListener("scroll", cb);

        return () => {
            if(!wrapper.current) return;

            wrapper.current.removeEventListener("resize", cb);
            wrapper.current.removeEventListener("scroll", cb);
        };
    }, [onFetch, blocked, setBlocked]);

    useEffect(() => {
        setBlocked(false);
        if(props.children.length > prevCount) return;

        scrollTop();
    }, [props.children.length]);

    useEffect(() => {
        setBlocked(false);
    }, [hasMore]);

    useEffect(() => {
        if(!isInViewport()) return;

        onFetch();
    }, [props.children.length]);

    return (
        <Element className={className} ref={wrapper}>
            {
                props.children.length > 0 &&
                props.children
            }
            {
                props.children.length <= 0 &&
                placeholder
            }
            {
                blocked &&
                <_loader.type>{ _loader.props.children }</_loader.type>
            }
            {
                hasMore &&
                <_loader.type ref={ref}>{ _loader.props.children }</_loader.type>
            }
        </Element>
    );
};

InfiniteList.propTypes = {
    children: PropTypes.node,
    metadata: PropTypes.object,
    loader:   PropTypes.func,
    element:  PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    onFetch:     PropTypes.func,
    className:   PropTypes.string,
    placeholder: PropTypes.object
};

export { InfiniteList };
