
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { defaultTimeSpans, timeUnits } from "../../helper/messageDeleteTimeSpans";
import { TextInput, Button, Modal, DropDownSelect } from "@circle/kip-components";

const TimespanDropdownToggle = ({ ...props }) => {
    const { translate } = useTranslation();
    const [modal, setModal] = useState(false);
    const [value, setValue] = useState(props.defaultValue || "6M");
    const [input, setInput] = useState({
        customTimeValue: "hour",
        customTimeInput: 1
    });

    const onSelectionChange = x => {
        setValue(x);

        if(x === "custom") return setModal(true);

        return props.onChange(defaultTimeSpans[x]?.multiplier ?? []);
    };

    const onCloseModal = () => {
        setModal(false);

        props.onChange(input.customTimeInput * timeUnits[input.customTimeValue].multiplier);
    };

    const onTimeChange = x => {
        setInput({ ...input, customTimeValue: x });
    };

    const options = Object.keys(defaultTimeSpans)?.map(x => ({
        value: x,
        label: x === "custom" && value === "custom" ?
            `${input.customTimeInput} ${translate(timeUnits[input.customTimeValue]?.translation)}` :
            translate(defaultTimeSpans[x].translation),
        checked: x === value
    }));

    const modalOptions = Object.keys(timeUnits).map(x => ({
        value:   x,
        label:   translate(timeUnits[x].translation),
        checked: x === input.customTimeValue
    }));

    return (
        <>
            <DropDownSelect
                closeOnOptionClick
                options={options}
                onClick={onSelectionChange}
            />
            <Modal
                title={translate("modal.custom")}
                isOpen={modal}
                isCloseable={false}
                onClose={() => setModal(false)}
                style={{ minHeight: "19rem", width: "28rem" }}
            >
                <div className="flex-column user-custom">
                    <div className="flex">
                        <TextInput
                            placeholder={translate("custom.input")}
                            type="number"
                            min={1}
                            value={input.customTimeInput}
                            onChange={e => setInput({ ...input, customTimeInput: e.target.value })}
                        />
                        <DropDownSelect
                            options={modalOptions}
                            onClick={onTimeChange}
                            popupSize="s"
                        />
                    </div>

                    <div className="flex mt-8 justify-end">
                        <Button onClick={() => setModal(false)}>
                            {translate("actions.no")}
                        </Button>
                        <Button _appearance="primary" onClick={onCloseModal}>
                            {translate("actions.yes")}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

TimespanDropdownToggle.propTypes = {
    defaultValue: PropTypes.string,
    onChange:     PropTypes.func
};

export { TimespanDropdownToggle };
