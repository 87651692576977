import { useTrigger } from "@circle/gestalt-app";
import { useEffect, useState } from "react";

const useQuery = ({ options, keys }) => {
    const { trigger }     = useTrigger();
    const [init, setInit] = useState(false);

    useEffect(() => {
        if(Object.keys(options).length === 0) return;

        trigger("applyQuery", options, keys);

        setInit(true);
    }, [options]);
    return [init];
};

const createQueryString = filters => {
    const format = value => typeof value === "string" ? value.replaceAll("+", "%2B") : value;

    return Object.keys(filters).reduce((dest, val) => dest.concat(`${val}=${filters[val] instanceof Array ? `[${filters[val]}]&` : `${format(filters[val])}&`}`), "").slice(0, -1);
};

export { useQuery, createQueryString };
