import PropTypes from "prop-types";
import { useTrigger } from "@circle/gestalt-app";
import { Button, Icon } from "@circle/kip-components";

const ActionColumns = ({ ...props }) => {
    const { trigger } = useTrigger();

    const onStarClick = e => {
        e.stopPropagation();

        trigger("toggleObservation", {
            id:              props.id,
            plant_id:        props.plant_id, // eslint-disable-line camelcase
            messageType:     props.messageType,
            system:          props.system,
            message:         props.message,
            messageEx:       props.messageEx,
            referenceObject: props.referenceObject
        });

        props.onDelete();
    };

    return (
        <td>
            <div>
                <Button _variant="icon" onClick={onStarClick}>
                    <Icon _icon="StarSolid"/>
                </Button>
            </div>
        </td>
    );
};

ActionColumns.propTypes = {
    id:              PropTypes.number,
    plant_id:        PropTypes.string, // eslint-disable-line camelcase
    messageType:     PropTypes.string,
    message:         PropTypes.string,
    messageEx:       PropTypes.string,
    referenceObject: PropTypes.string,
    system:          PropTypes.string,
    onDelete:        PropTypes.func
};

export { ActionColumns };
