import { useEffect } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { useBranch, useTrigger } from "@circle/gestalt-app";
import { Monitor } from "./Monitor";
import { DatatableMessage } from "../../types/DatatableMessage";
import { isUuid } from "../../helper/helper";

const MonitorRouter = () => {
    const params      = useParams();
    const { trigger } = useTrigger();
    const { monitor, messagesData, plants, selectedPlant } = useBranch({
        monitor:       ["monitor"],
        messagesData:  ["messages"],
        plants:        ["plants"],
        selectedPlant: ["selectedPlant"],
        calendar:      ["queryOptions", "calendar"]
    });

    const plantId  = isUuid(params.plantId) ? params.plantId : selectedPlant?.id;
    const selected = plants.find(elem => elem.id === plantId);
    const graph    = (monitor.graph[plantId] || [])
        .map(x => ({
            ...x,
            selector: {
                from:  new Date(x.from),
                until: new Date(x.until)
            }
        }));
    const messages = messagesData
        .filter(x => x.plant_id === plantId)
        .map(elem => new DatatableMessage(elem));

    useEffect(() => {
        if(!params.plantId) trigger("onPlantSelect", selectedPlant?.id);

        trigger("onPlantSelect", params.plantId);
    }, [params.plantId]); //eslint-disable-line

    return (
        <Routes>
            <Route path=":messageGroup" element={<Monitor selected={selected} graph={graph} messages={messages}/>}/>
            <Route path="/" element={<Monitor selected={selected} graph={graph} messages={messages}/>}/>
            <Route path="*" element={<Navigate to="/monitor" replace/>}/>
        </Routes>
    );
};

export { MonitorRouter };
