import { useState } from "react";
import { useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";
import styles from "./accordion.module.scss";
import { Button, Icon } from "@circle/kip-components";
import { toWeekdayDate } from "../../helper/formatTimeSpan";

const Accordion = ({ ...props }) => {
    const { translate } = useTranslation();
    const [isOpen, setOpen] = useState(false);

    return (
        <div className={styles.accordionWrapper}>
            <div
                className={resolveClassNames(styles.accordionTitle, isOpen ? styles.open : "")}
                onClick={() => setOpen(!isOpen)}
            >
                <span>{ props.name }</span>
                <Button
                    _variant="icon"
                    className={styles.causeButton}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.onClick();
                    }}
                >
                    <Icon _icon="Search" />
                </Button>
                <Icon _icon="ChevronUp" className={resolveClassNames(styles.icon, isOpen ? (styles.open, styles.iconRev) : "")}/>
            </div>
            <div className={resolveClassNames(styles.accordionItem, !isOpen ? styles.collapsed : "")}>
                <div className={styles.accordionContent}>
                    <ol>
                        {
                            props.items?.map((item, key) =>
                                <li key={key}>
                                    <span>{toWeekdayDate(new Date(item?.startTime), props.locale)} {(item.count > 1 ? translate("overview.report.cause.occurences", props.locale) : translate("overview.report.cause.occurence", props.locale)).replace("X", item.count)}</span>
                                </li>

                            )
                        }
                    </ol>
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    name:    PropTypes.string,
    items:   PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func,
    locale:  PropTypes.string
};

export { Accordion };
