import { useBranch, useTranslation, getLanguageBy } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { Datatable } from "../generic/Datatable";
import { ActionColumns } from "./ActionColumns";
import { Column } from "../generic/Column";
import { Icon } from "@circle/kip-components";
import names from "../../enums/names";
import icons from "../../enums/icons";

import styles from "./observationtables.module.scss";
import { useState } from "react";
import { useEffect } from "react";

const ObservationTables = ({ onSelect = x => x, ...props }) => {
    const { translate } = useTranslation();
    const { observations } = useBranch({
        observations: ["mappedObservations"]
    });
    const [sortingSettings, setSortingSettings] = useState([]);

    const allObservations = observations.length > 0 ? observations : [{ group: "", items: [] }];

    const onHeadlineClick = (itemId, elem) => {
        const item = sortingSettings.find(x => x.itemId === itemId);

        if(item.selected !== elem)
            return setSortingSettings(sortingSettings.map(x => x.itemId !== itemId ? x : ({
                ...x,
                selected: elem,
                order:    "desc"
            })));


        return setSortingSettings(sortingSettings.map(x => x.itemId !== itemId ? x : ({
            ...x,
            order: (x.order === "desc" || x.order === null) ? "asc" : "desc"
        })));
    };

    const onRowClick = elem => {
        onSelect(elem.id);
    };

    useEffect(() => {
        setSortingSettings(allObservations.map((x, key) => ({ itemId: key, selected: "frequency", order: "desc", language: getLanguageBy(window.config.languages.map(y => y.value)) })));
    }, [allObservations.length]);

    return (
        <>
            {
                allObservations.map((observation, key) => {
                    const sorting = sortingSettings.find(x => key === x.itemId);
                    const items = [...observation.items];

                    items.sort((a, b) => {
                        const sortProp = sorting?.selected || "frequency";

                        return `${a[sortProp]}`.localeCompare(`${b[sortProp]}`);
                    });
                    if(sorting?.order === "desc")
                        items.reverse();

                    return (
                        <div className={styles.content} key={`${key}-${sorting?.order}-${sorting?.selected}`}>
                            <span className={styles.groupHead}>{ observation.group ?? translate("detail.plant.overall") }</span>
                            <Datatable
                                content={[...items]}
                                onHeadlineClick={onHeadlineClick.bind(this, key)}
                                onRowClick={onRowClick}
                                selectedMsg={props.selected}
                                refs={["id", "system", "message", "messageEx", "referenceObject", "plant_id", "messageType"]}
                                actions={<ActionColumns onDelete={() => props.onClick(null)}/>}
                                sortingSettings={sorting}
                            >
                                <Column
                                    title="message.messageType"
                                    item="messageType"
                                    sortable
                                    width="75px"
                                    filter="messageType"
                                    render={x => <Icon className={names[x]} _icon={icons[x]}/>}
                                />
                                <Column
                                    title="message.system"
                                    item="system"
                                    sortable
                                    width="100px"
                                    filter="system"
                                />
                                <Column
                                    title="message.refObj"
                                    item="referenceObject"
                                    sortable
                                    filter="referenceObject"
                                />
                                <Column
                                    title="message.text"
                                    item="message"
                                    sortable
                                    filter="message"
                                />
                                <Column
                                    title="message.frequency"
                                    item="frequency"
                                    width="110px"
                                    sortable
                                    filter="frequency"
                                    render={x => x ?? "–"}
                                />
                                <Column
                                    title="message.avg_duration"
                                    item="duration"
                                    width="120px"
                                    sortable
                                    filter="duration"
                                    render={x => x ?? "–"}
                                />
                            </Datatable>
                        </div>
                    );
                })
            }
        </>
    );
};

ObservationTables.propTypes = {
    onSelect: PropTypes.func,
    onClick:  PropTypes.func,
    selected: PropTypes.number
};

export { ObservationTables };
