import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";

const ProcessGraph = ({ ...props }) => {
    const { translate } = useTranslation();

    return (
        <div className="process-graph">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    id="process-graph"
                    data={props.data}
                    margin={{
                        top:    20,
                        right:  25,
                        left:   20,
                        bottom: 20
                    }}
                    background={{ fill: "#333" }}
                >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="hour" label={{ value: translate("graph.time"), position: "bottom", offset: -5, stroke: "#4F5358" }}/>
                    <YAxis axisLine={false} allowDecimals={false} tickLine={false} width={40} label={{ value: translate("graph.amount"), angle: -90, position: "left", stroke: "#4F5358" }} />
                    <Line type="monotone" dataKey="count" stroke="#333" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

ProcessGraph.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
};

export { ProcessGraph };
