import { useState } from "react";
import { TextInput } from "@circle/kip-components";
import PropTypes from "prop-types";
import styles from "./dateInput.module.scss";
import { dateInputFormat } from "../../../helper/date";

const DateInput = ({ ...props }) => {
    const [date, setDate] = useState(props.date);

    const onChange = val => {
        const value = val.target.value;

        if(!value || parseInt(value, 10) < 2000) return null;

        const dat = new Date(value);

        if(props.id === "from") {
            dat.setHours(0, 0, 0, 0);

            setDate(new Date(value));
            return props.onChange(new Date(dat));
        }
        dat.setHours(23, 59, 59, 999);
        setDate(new Date(value));
        return props.onChange(new Date(dat));
    };

    return (
        <span className={styles.dateInputContainer}>
            <TextInput isEnabled={!props.isReadonly} onChange={onChange} type="date" icon="DatePicker" className={styles.dateInp} value={dateInputFormat(date.toLocaleDateString("de"))} />
        </span>
    );
};

DateInput.propTypes = {
    date:       PropTypes.instanceOf(Date),
    onChange:   PropTypes.func,
    id:         PropTypes.string,
    isReadonly: PropTypes.bool
};

export { DateInput };
