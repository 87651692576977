import React, { useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { enhanceComponent } from "../helper/detector";

const Toggle = ({ isOpen, onClose = x => x, className = "", ...props }, ref) => {
    useImperativeHandle(ref, () => ({
        handleClickOutside() {
            if(!isOpen) return null;

            return onClose();
        }
    }));

    return (
        <>
            {
                isOpen &&
                <div className={`${className} dropdown w-dropdown`}>
                    <div className="dropdown-toggle w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex="0"/>
                    <nav className="dropdown-list w-dropdown-list w--open" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                        { props.children }
                    </nav>
                </div>
            }
        </>
    );
};

Toggle.displayName = "DropdownToggle";
Toggle.propTypes   = {
    isOpen:    PropTypes.bool.isRequired,
    onClose:   PropTypes.func,
    className: PropTypes.string,
    children:  PropTypes.node
};

const DropdownToggle = enhanceComponent(React.forwardRef(Toggle));

export { DropdownToggle };
