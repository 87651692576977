import { useEffect } from "react";
import { ContentHeader } from "../ContentHeader";
import { SettingsConfig } from "./SettingsConfig";
import { SettingsTile } from "./SettingsTile";
import { SettingsToggleTile } from "./SettingsToggleTile";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { resolveClassNames } from "palstek";

import styles from "./adminRouter.module.scss";

const AdminRouter = () => {
    const { translate } = useTranslation();
    const { trigger }   = useTrigger();
    const { plants, settings }    = useBranch({
        plants:   ["licensedPlants"],
        settings: ["messageReceiveSettings"]
    });

    useEffect(() => {
        trigger("applyPlant", null);
    }, []); //eslint-disable-line

    return (
        <div className={resolveClassNames(styles.admin, styles.flexColumn, styles.flexGrow)}>
            <ContentHeader headline="settings.content.header"/>
            <div className={resolveClassNames(styles.subheading)}>
                <span>{translate("settings.content.subheader.deletemessage")}</span>
            </div>
            <SettingsConfig/>
            <div className={resolveClassNames(styles.settingsSplit, styles.flexGrow)}>
                <div className={resolveClassNames(styles.settingsTileBlock, styles.flex)}>
                    {
                        plants.map((plant, key) => <SettingsTile key={key} plant={plant}/>)
                    }
                </div>
            </div>
            <div className={resolveClassNames(styles.subheading, styles.marginSubheading)}>
                <span>{translate("settings.content.subheader.receivemessage")}</span>
            </div>
            <div className={resolveClassNames(styles.settingsSplit, styles.flexGrow)}>
                <div className={resolveClassNames(styles.settingsTileBlock, styles.flex)}>
                    {
                        settings.map((setting, key) => <SettingsToggleTile key={key} {...setting}/>)
                    }
                </div>
            </div>
        </div>
    );
};

export { AdminRouter };
