import { useState, useEffect } from "react";
import { Button, Icon, TextInput } from "@circle/kip-components";
import PropTypes from "prop-types";
import { resolveClassNames, useOutsideClickHandler } from "palstek";
import { v4 as uuid } from "uuid";

import styles from "./groupselector.module.scss";
import { useBranch, useTranslation } from "@circle/gestalt-app";

const GroupSelector = ({ onClose = x => x, onSubmit = x => x, ...props }) => {
    const { groups } = useBranch({
        groups: ["groups"]
    });
    const [selected, setSelected] = useState([]);
    const [values, setValues] = useState(groups);
    const [text, setText] = useState("");
    const { translate } = useTranslation();

    const _onClose = () => {
        setText("");
        setSelected([]);
    };

    const outsideClickHandler = (e, targetRef) => {
        if(e.target && targetRef?.contains(e.target)) return;

        _onClose();
        onClose();
    };
    const [ref] = useOutsideClickHandler(outsideClickHandler, props.isActive);

    const onSelect = id => {
        setSelected(selected.includes(id) ? selected.filter(x => x !== id) : selected.concat(id));
    };

    const onCreate = e => {
        if(e.keyCode !== 13) return;

        const id = uuid();

        setSelected(selected.concat(id));
        setValues(values.concat({
            id:   id,
            name: text
        }));
        setText("");
    };


    const _onSubmit = () => {
        onSubmit(selected.map(x => values.find(y => y.id === x)));

        _onClose();
    };

    const onChange = e => {
        setText(e.target.value);
    };

    useEffect(
        () => {
            setValues(groups);
        }, [groups]
    );

    return (
        <>
            {
                props.isActive &&
                <div className={styles.windowContainer}>
                    <div ref={ref} className={styles.modal}>
                        <div className={styles.header}>
                            <span>{ translate("group.button.text")}</span>
                            <Button disabled={selected.length === 0} _variant="icon" className={styles.submit} onClick={() => _onSubmit()}>
                                <Icon _icon="Check"/>
                            </Button>
                        </div>
                        <div className={resolveClassNames(styles.input, styles.row)} onKeyDown={onCreate}>
                            <TextInput value={text} placeholder={translate("group.button.create")} onChange={onChange}/>
                        </div>
                        <div className={styles.list}>
                            {
                                values.map((x, key) => (
                                    <div key={key} className={resolveClassNames(styles.row, styles.rowSelect)} onClick={() => onSelect(x.id)}>
                                        <Icon _icon="Check" className={resolveClassNames(styles.groupIcon, selected.includes(x.id) && styles.visible)}/>
                                        <span>{ x.name }</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </>

    );
};

GroupSelector.propTypes = {
    isActive: PropTypes.bool,
    onClose:  PropTypes.func,
    onSubmit: PropTypes.func
};

export { GroupSelector };
