import { useTranslation, useTrigger } from "@circle/gestalt-app";
import { Icon, SwitchButton } from "@circle/kip-components";
import PropTypes from "prop-types";
import { useState } from "react";
import { toMinuteString } from "../helper/helper";

import styles from "./hitoryTable.module.scss";

const HistoryTable = ({ ...props }) => { // eslint-disable-line complexity
    const { trigger } = useTrigger();
    const [currentArrow, setCurrentArrow] = useState("down");
    const { translate, currentLanguage } = useTranslation();
    const [tableFilter, setTableFilter]  = useState("startTime");

    const onSetFilter = val => {
        if(val === tableFilter)
            setCurrentArrow(currentArrow === "down" ? "up" : "down");
        else
            setCurrentArrow("down");

        setTableFilter(val);

        trigger("orderHistoryBy", val);
    };

    const onSwitchTableFilter = val => {
        props.onSwitchTableFilter(val);
    };

    const filterValues = [
        {
            value:   "aggregated",
            checked: props.tableSwitchFilter === "aggregated",
            label:   translate("graph.aggregated.view")
        },
        {
            value:   "chronologic",
            checked: props.tableSwitchFilter === "chronologic",
            label:   translate("graph.chronological.view")
        }
    ];

    return (
        <div className="flex form-row mt-2">
            <div className="form-column flex-column flex-grow">
                <span className="form-header font-bold">
                    { translate("message.history") }
                </span>
                <div className="message-history flex-column flex-grow">
                    <div className="filter-container flex relative justify-center">
                        <SwitchButton className="switchFilter" options={filterValues} onChange={e => onSwitchTableFilter(e.target.value)} />
                    </div>
                    <div className="flex col-1 sidebar-box">

                        {
                            props.tableSwitchFilter === "aggregated" &&
                            <>
                                <div className="flex-column col-2">
                                    <span className="clickable font-bold" onClick={() => onSetFilter("startTime")}>{ translate("form.timerange") }
                                        { tableFilter === "startTime" &&
                                            <Icon className={styles.arrowIcon} _icon={currentArrow === "down" ? "ArrowDown" : "ArrowUp"} />
                                        }
                                    </span>
                                    {
                                        props.history.map((x, key) => (
                                            <span key={key}>{`${new Date(x.startTime).toLocaleDateString(currentLanguage, { timeZone: "UTC", day: "2-digit", month: "2-digit", year: "numeric" })} ${`0${new Date(x.startTime).getUTCHours()}`.slice(-2)} Uhr`}</span>
                                        ))
                                    }
                                </div>
                                <div className="flex-column col-3">
                                    <span className="clickable font-bold" onClick={() => onSetFilter("duration")}>{ translate("form.duration") }
                                        { tableFilter === "duration" &&
                                            <Icon className={styles.arrowIcon} _icon={currentArrow === "down" ? "ArrowDown" : "ArrowUp"} />
                                        }
                                    </span>
                                    {
                                        props.history.map((x, key) => (
                                            <span key={key}>{toMinuteString(x.duration)}</span>
                                        ))
                                    }
                                </div>
                                <div className="flex-column">
                                    <span className="clickable font-bold" onClick={() => onSetFilter("amount")}>{ translate("form.amount") }
                                        { tableFilter === "amount" &&
                                            <Icon className={styles.arrowIcon} _icon={currentArrow === "down" ? "ArrowDown" : "ArrowUp"} />
                                        }
                                    </span>
                                    {
                                        props.history.map((x, key) => (
                                            <span key={key} className="align-right">{x.amount}</span>
                                        ))
                                    }
                                </div>
                            </>
                        }
                        {
                            props.tableSwitchFilter === "chronologic" &&
                            <>
                                <div className="flex-column col-2">
                                    <span onClick={() => onSetFilter("startTime")} className="clickable font-bold">{ translate("message.startTime")}
                                        { tableFilter === "startTime" &&
                                            <Icon className={styles.arrowIcon} _icon={currentArrow === "down" ? "ArrowDown" : "ArrowUp"} />
                                        }
                                    </span>
                                    {
                                        props.historyChrono?.map((x, key) => (
                                            <span key={key} className="align-left">{`${new Date(x.startTime).toLocaleDateString(currentLanguage, { timeZone: "UTC", day: "2-digit", month: "2-digit", year: "numeric" })} ${`0${new Date(x.startTime).getUTCHours()}`.slice(-2)}:${`0${new Date(x.startTime).getUTCMinutes()}`.slice(-2)}:${`0${new Date(x.startTime).getUTCSeconds()}`.slice(-2)}`}</span>
                                        ))
                                    }
                                </div>
                                <div className="flex-column col-2">
                                    <span onClick={() => onSetFilter("endTime")} className="clickable font-bold">{ translate("message.endTime") }
                                        { tableFilter === "endTime" &&
                                            <Icon className={styles.arrowIcon} _icon={currentArrow === "down" ? "ArrowDown" : "ArrowUp"} />
                                        }
                                    </span>
                                    {
                                        props.historyChrono?.map((x, key) => (
                                            <span key={key} className="align-left">{`${new Date(x.endTime).toLocaleDateString(currentLanguage, { timeZone: "UTC", day: "2-digit", month: "2-digit", year: "numeric" })} ${`0${new Date(x.endTime).getUTCHours()}`.slice(-2)}:${`0${new Date(x.endTime).getUTCMinutes()}`.slice(-2)}:${`0${new Date(x.endTime).getUTCSeconds()}`.slice(-2)}`}</span>
                                        ))
                                    }
                                </div>
                                <div className="flex-column col-3">
                                    <span onClick={() => onSetFilter("duration")} className="clickable font-bold">{ translate("message.duration") }
                                        { tableFilter === "duration" &&
                                            <Icon className={styles.arrowIcon} _icon={currentArrow === "down" ? "ArrowDown" : "ArrowUp"} />
                                        }
                                    </span>
                                    {
                                        props.historyChrono?.map((x, key) => (
                                            <span key={key} className="align-left">
                                                { toMinuteString((new Date(x.endTime).getTime() - new Date(x.startTime).getTime()) / 1000) }
                                            </span>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

HistoryTable.propTypes = {
    history:             PropTypes.array,
    historyChrono:       PropTypes.array,
    onSwitchTableFilter: PropTypes.func,
    tableSwitchFilter:   PropTypes.string
};

export { HistoryTable };
