import { useState, useEffect, useRef } from "react";

const enhanceComponent = Child => {
    const Wrapper = props => {
        const ref      = useRef();
        const childRef = useRef();

        const [triggered, setTriggered] = useState(false);
        const handleClickOutside = e => {
            const func = childRef.current.handleClickOutside ? childRef.current.handleClickOutside : () => null;

            return func(e);
        };

        useEffect(() => {
            const isTriggered     = (path = []) => path.filter(elem => elem.classList && elem.classList.contains("click-detector")).length === 0;
            const handleMouseDown = e => {
                const path = e.path || (e.composedPath && e.composedPath());

                if(!isTriggered(path)) return;

                setTriggered(true);
            };
            const handleMouseUp = e => {
                const path = e.path || (e.composedPath && e.composedPath());

                if(!triggered || !isTriggered(path)) return;

                setTriggered(false);
                handleClickOutside(e);
            };

            document.addEventListener("mousedown", handleMouseDown, true);
            document.addEventListener("mouseup", handleMouseUp, true);

            return () => {
                document.removeEventListener("mousedown", handleMouseDown, true);
                document.removeEventListener("mouseup", handleMouseUp, true);
            };
        }, [triggered]);

        return (
            <div className="click-detector" ref={ref}>
                <Child ref={childRef} {...props}/>
            </div>
        );
    };

    return Wrapper;
};

export { enhanceComponent };
