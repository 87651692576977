import React from "react";
import PropTypes from "prop-types";

const Accordeon = ({ className = "", isOpen = false, prefix = "accordeon", header = <div/>, onClose = x => x, onOpen = x => x, ...props }) => {
    const toggle = e => {
        if(props.disabled) return null;
        if(isOpen)   return onClose(e);

        return onOpen(e);
    };

    return (
        <div className={`${prefix} ${className}${isOpen ? " is-open" : ""}`}>
            <div className={`${prefix}-header`} onClick={toggle}>
                {
                    React.cloneElement(header, { disabled: props.disabled })
                }
            </div>
            <div className={`${prefix}-content flex-column`}>
                {
                    props.children
                }
            </div>
        </div>
    );
};

Accordeon.propTypes = {
    className: PropTypes.string,
    prefix:    PropTypes.string,
    isOpen:    PropTypes.bool,
    disabled:  PropTypes.bool,
    header:    PropTypes.node,
    onClose:   PropTypes.func,
    onOpen:    PropTypes.func,
    children:  PropTypes.node
};

export { Accordeon };
