import { useTranslation } from "@circle/gestalt-app";
import { Icon } from "@circle/kip-components";
import PropTypes from "prop-types";
import { resolveClassNames } from "palstek";

import styles from "./actionFooter.module.scss";

const ActionFooter = ({ isDuration = false, isFrequency = false, ...props }) => {
    const { translate } = useTranslation();

    const enabledClassNames = resolveClassNames(styles.buttonContainer, styles.click);
    const stylesDisabled    = [styles.buttonContainer, styles.disabled];

    return (
        <div className={styles.actionFooter}>
            <label className={props.disabled || !isDuration ? resolveClassNames(...stylesDisabled, !isDuration ? styles.greyed : "") : enabledClassNames} htmlFor="duration">
                <Icon _icon="BarChart"/>
                <b>{translate("monitor.duration")}</b>
                <input disabled={props.disabled} type="checkbox" id="duration" onChange={() => props.onViewChange("duration")}/>
            </label>
            <label htmlFor="frequency" className={props.disabled || !isFrequency ? resolveClassNames(...stylesDisabled, !isFrequency ? styles.greyed : "") : enabledClassNames}>
                <Icon _icon="LineChart"/>
                <input disabled={props.disabled} type="checkbox" id="frequency" onChange={() => props.onViewChange("frequency")}/>
                <b>{translate("filter.count")}</b>
            </label>
        </div>
    );
};

ActionFooter.propTypes = {
    isDuration:   PropTypes.bool,
    isFrequency:  PropTypes.bool,
    onViewChange: PropTypes.func,
    disabled:     PropTypes.bool
};

export { ActionFooter };
