import { useEffect } from "react";
import { OverviewItem } from "./OverviewItem";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import styles from "./overview.module.scss";
import { Icon } from "@circle/kip-components";
import { useQueryString } from "../../hooks/querystring";
import { useQuery } from "../../hooks/query";
import { getRangeString } from "../../helper/date";
import { RangeSelector } from "../generic/rangeSelector/RangeSelector";
import { usePrevious } from "palstek";
import deepEqual from "deep-equal";

const Overview = () => {
    const { translate, currentLanguage } = useTranslation();
    const { trigger } = useTrigger();
    const { plants, options } = useBranch({
        plants:  ["plantsOverview"],
        options: ["queryOptions"]
    });

    const prevOpts = usePrevious(options);
    const [filter] = useQueryString({
        options:  options,
        plants:   plants,
        optional: {
            range: x => (x ?? "").replace("undefined", "") || options.range || "last7Days"
        }
    });

    const onDatePick = date => {
        trigger("applyOption", "range", date.name);
        trigger("applyOption", "calendar", date);
    };

    useQuery({
        options: filter,
        keys:    ["range", "startTime"]
    });

    useEffect(() => {
        trigger("applyPlant", null);
    }, []);

    useEffect(() => {
        if(deepEqual(prevOpts, options)) return x => x;

        if(!options.range) return x => x;

        const startTime = JSON.stringify([options.calendar.from.getTime() / 1000, options.calendar.until.getTime() / 1000]);

        trigger("fetchOverview", options.range, startTime);

        const interval = setInterval(() => trigger("fetchOverview", options.range, startTime, true), 30000);

        return () => clearInterval(interval);
    }, [options]);

    useEffect(() => {
        trigger("onPlantSelect", {});
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Icon className={styles.home} _icon="HomeSolid"/>
                <RangeSelector
                    range={options.range}
                    selected={options.calendar}
                    onChange={onDatePick}
                    isOverview={true}
                />
            </div>
            <div className={styles.headline}>
                <span>{ translate("overview.content.header") }</span>
                <span className={styles.timeRange}>{ options.range === "shift" ? translate("overview.selector.shift") : getRangeString([options.calendar.from, options.calendar.until], currentLanguage)}</span>
            </div>
            <div className={styles.overviewContainer}>
                {
                    plants?.map((plant, key) => <OverviewItem key={key} plant={plant}/>)
                }
            </div>
        </div>
    );
};

export { Overview };
