import { toMinuteString  } from "../helper/helper";

interface Message {
    id: number | string,
    plant_id: string,
    messageType: string,
    system: string,
    referenceObject_Sign: string,
    referenceObject_Descr: string,
    message: string,
    messageEx: string,
    startTime: string,
    endTime: string
    messageGroups: [],
    hash: string
}

interface IDatatableMessage {
    id: number | string,
    plant_id: string,
    type: string,
    system: string,
    referenceObject: string,
    refObjSign: string,
    refObjDescr: string,
    message: string,
    additionalText: string,
    startTime: Date | undefined,
    endTime: Date | undefined,
    duration: string | undefined,
    hash: string
}

class DatatableMessage implements IDatatableMessage {
    constructor(message: Message) { // eslint-disable-line complexity
        this.id              = message?.id ?? "";
        this.plant_id        = message?.plant_id ?? ""; // eslint-disable-line camelcase
        this.type            = message?.messageType ?? "";
        this.system          = message?.system ?? "";
        this.referenceObject = `${message?.referenceObject_Sign ?? ""} | ${message?.referenceObject_Descr ?? ""}`;
        this.refObjSign      = message?.referenceObject_Sign ?? "";
        this.refObjDescr     = message?.referenceObject_Descr ?? "";
        this.message         = message?.message ?? "";
        this.additionalText  = message?.messageEx ?? "";
        this.messageGroups   = message?.messageGroups ?? [];
        this.hash           = message?.hash ?? "";

        if(message?.startTime) this.startTime = new Date(message.startTime);
        if(message?.endTime)   this.endTime = new Date(message.endTime);
        if(message?.startTime && message.endTime) this.duration = toMinuteString(((new Date(message.endTime).getTime() - new Date(message.startTime).getTime()) / 1000));
    }
    id: string | number;
    plant_id: string; // eslint-disable-line camelcase
    type: string;
    system: string;
    referenceObject: string;
    refObjSign: string;
    refObjDescr: string;
    message: string;
    additionalText: string;
    startTime: Date | undefined;
    endTime: Date | undefined;
    duration: string | undefined;
    messageGroups: [] | undefined;
    hash: string;

    clone(): DatatableMessage {
        return structuredClone(this);
    }
}

export { DatatableMessage };
