import PropTypes from "prop-types";
import icons from "../../enums/icons";
import { Icon, Button } from "@circle/kip-components";
import { useBranch, useTrigger } from "@circle/gestalt-app";
import { useParams } from "react-router-dom";

const colors = {
    err:    "#eb6060",
    task:   "#ff9966",
    warn:   "#f7e463",
    info:   "#7da7d9",
    system: "#4F5358",
    log:    "#7d7d7d"
};

const Aggregate = ({ className = "", onClick = x => x, ...props }) => {
    const { trigger }      = useTrigger();
    const params           = useParams();
    const { observations } = useBranch({
        observations: ["observations"]
    });

    const isActive = Boolean(observations.find(x => (
        x.message === props.message &&
        x.messageType === props.messageType &&
        x.plant_id === params.plantId &&
        x.referenceObject === props.reference &&
        x.system === props.system
    )));

    const onStarClick = e => {
        e.stopPropagation();

        trigger("toggleObservation", {
            id:              props.id,
            plant_id:        params.plantId, // eslint-disable-line camelcase
            messageType:     props.messageType,
            system:          props.system,
            message:         props.message,
            referenceObject: props.reference
        });
    };

    return (
        <div className={`${className} accordeon-row`} onClick={onClick}>
            <div className="accordeon-cell">
                <Icon
                    _icon={icons[props.messageType]}
                    style={{
                        color: colors[props.messageType]
                    }}
                />
            </div>
            <div className="accordeon-cell">
                <span>{ props.system }</span>
            </div>
            <div className="accordeon-cell">
                <span>{ props.reference }</span>
            </div>
            <div className="accordeon-cell">
                <span>{ props.message }</span>
            </div>
            <div className="accordeon-cell horizontal-end vertical-align">
                <span>{ props.count }</span>
            </div>
            <div className="accordeon-cell horizontal-end vertical-align">
                <span>{ props.duration }</span>
            </div>
            <div className="accordeon-cell horizontal-end vertical-align">
                <span>{ props.average }</span>
            </div>
            <div className="accordeon-cell horizontal-end vertical-align">
                <Button _variant="icon" onClick={onStarClick}>
                    <Icon _icon={isActive ? "StarSolid" : "Star"}/>
                </Button>
            </div>
        </div>
    );
};

Aggregate.propTypes = {
    plant_id:    PropTypes.string, // eslint-disable-line camelcase
    className:   PropTypes.string,
    system:      PropTypes.string,
    reference:   PropTypes.string,
    message:     PropTypes.string,
    messageEx:   PropTypes.string,
    messageType: PropTypes.string,
    count:       PropTypes.number,
    duration:    PropTypes.string,
    average:     PropTypes.string,
    onClick:     PropTypes.func,
    id:          PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export { Aggregate };

