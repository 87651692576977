import PropTypes from "prop-types";
import { Icon, TextInput } from "@circle/kip-components";
import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from "@circle/gestalt-app";

import styles from "./multiselect.module.scss";

const MultiSelect = ({ onChange = x => x, initialValue = [] }) => {
    const ref = useRef();
    const [text, setText] = useState("");
    const [values, setValues] = useState(initialValue);
    const [focus, setFocus] = useState(false);
    const { translate } = useTranslation();

    const _onChange = e => {
        setText(e.target.value);
    };
    const onSelect = e => {
        if(e.keyCode !== 13 || text.trim().length === 0) return;

        setValues(values.concat({
            id:    uuid(),
            value: text
        }));
        setText("");
    };
    const onDelete = id => {
        setValues(values.filter(x => x.id !== id));

        ref.current.focus();
    };
    const onFocus = () => {
        setFocus(true);

        ref.current.focus();
    };

    useEffect(() => {
        onChange(values);
    }, [values]);

    return (
        <div className={styles.select} onKeyDown={onSelect} onClick={onFocus} onBlur={() => setFocus(false)}>
            {
                !focus && values.length === 0 &&
                <span className={styles.empty}>{ translate("actions.search") }</span>
            }
            {
                values.length > 0 &&
                <div className={styles.list}>
                    {
                        values.map(x => (
                            <div className={styles.item} key={x.id}>
                                <span>{ x.value }</span>
                                <Icon className={styles.icon} _icon="Close" onClick={() => onDelete(x.id)}/>
                            </div>
                        ))
                    }
                </div>
            }
            <TextInput value={text} onChange={_onChange} ref={ref}/>
        </div>
    );
};

MultiSelect.propTypes = {
    onChange:     PropTypes.func,
    initialValue: PropTypes.arrayOf(PropTypes.object)
};

export { MultiSelect };
