import { useState } from "react";
import { Button, Icon, Modal } from "@circle/kip-components";
import { useTranslation, useTrigger } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import styles from "./causeModal.module.scss";
import { CauseForm }  from "./CauseForm";

const CauseModal = ({ ...props }) => {
    const { translate }     = useTranslation();
    const { trigger }       = useTrigger();
    const [modal, setModal] = useState(false);

    const onCloseModal = () => {
        setModal(false);
    };

    const onSubmitCause = elem => {
        trigger("createCauseReport", "cause_reports", elem);
        setModal(false);
    };

    return (
        <>
            {
                props.isIconView &&
                <span>
                    <Button
                        _variant="icon"
                        className={props.isSmall ? styles.smallButton : ""}
                        disabled={!props.selectedPlant?.id}
                        onClick={e => {
                            e.preventDefault();
                            setModal(true);
                        }}
                    >
                        <Icon _icon="Add" />
                    </Button>
                </span>
            }
            {
                !props.isIconView &&
                <Button
                    className={props.marginLeft && styles.reportButton}
                    onClick={e => {
                        e.preventDefault();
                        trigger("plantIdSet", props.selectedPlant.id);
                        setModal(true);
                    }}
                >
                    <b>
                        <Icon className="mr-2" _icon="Add" />
                        {translate("overview.report.button")}
                    </b>
                </Button>
            }

            <Modal
                title={translate("plant.edit")}
                isOpen={modal}
                isCloseable={false}
                onClose={() => setModal(false)}
                className="cause-modal"
            >
                <CauseForm
                    isEditable={true}
                    data={props.selectedMessage}
                    selectedPlant={props.selectedPlant}
                    routines={props.routines}
                    onClose={onCloseModal}
                    onSubmit={onSubmitCause}
                />
            </Modal>
        </>
    );
};

CauseModal.propTypes = {
    selectedPlant:   PropTypes.object,
    routines:        PropTypes.arrayOf(PropTypes.object),
    isIconView:      PropTypes.bool,
    marginLeft:      PropTypes.bool,
    selectedMessage: PropTypes.object,
    isSmall:         PropTypes.bool
};

export { CauseModal };
