import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "@circle/gestalt-app";
import { Button, Modal, Card, Icon } from "@circle/kip-components";
import { FileUpload } from "../fileUpload/FileUpload";
import styles from "./edit-file-modal.module.scss";
import { File } from "../../../types/File";
import { resolveClassNames } from "palstek";


const EditFileModal = ({ ...props }) => {
    const { translate }     = useTranslation();
    const [modal, setModal] = useState(false);
    const [value, setValue] = useState(props.file);
    const file = new File({ url: props.file });

    const onClose = () => {
        setValue(props.file);
        setModal(!modal);
    };

    const handleDeleteClick = e => {
        e.stopPropagation();
        e.preventDefault();
        setValue(null);
        props.onImageChange("img", null);
    };

    const onSubmit = () => {
        if(!value) return;
        setValue(value);
        props.onImageChange("img", value);
        onClose();
    };

    const onChange = x => {
        setValue(x);
    };

    useEffect(() => {
        setValue(props.file);
    }, [props.file]);

    return (
        <>
            <div className={styles.cardContainer}>
                <Card className={resolveClassNames(styles.card, "img_card")} onClick={props.isModalEnable ? () => setModal(!modal) : x => x}>
                    {!props.file && <Icon _icon="Add"/>}
                    {(props.file && file.type === "image") && <img src={props.file}/>}
                </Card>
                {(props.file && !props.readOnly) &&
                <div className={styles.deleteButton}>
                    <span>
                        <Button _variant="icon" onClick={handleDeleteClick}>
                            <Icon _icon="Trashcan" />
                        </Button>
                    </span>
                </div>
                }
            </div>

            <Modal
                title={translate("modal.media")}
                isOpen={modal}
                onClose={onClose}
                className={styles.modal}
            >
                <FileUpload
                    value={value}
                    isFileNull={!value}
                    placeholder="/icons/image-solid.png"
                    readOnly={props.readOnly}
                    onChange={x => onChange(x ? x.file : null)}
                />
                <div className={styles.actionContainer}>
                    {!props.readOnly &&
                    <>
                        <Button onClick={onClose}>
                            { translate("actions.cancel") }
                        </Button>
                        <Button _appearance="primary" disabled={!value} onClick={onSubmit}>
                            { translate("actions.save") }
                        </Button>
                    </>
                    }
                    {
                        props.readOnly &&
                        <Button onClick={onClose}>
                            { translate("actions.close") }
                        </Button>
                    }
                </div>
            </Modal>
        </>
    );
};

EditFileModal.propTypes = {
    onImageChange: PropTypes.func,
    file:          PropTypes.string,
    isModalEnable: PropTypes.bool,
    readOnly:      PropTypes.bool
};

export { EditFileModal };
