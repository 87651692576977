import { useEffect, useState } from "react";
import { toWeekdayDate } from "../helper/formatTimeSpan";

const useDate = locale => {
    const [date, setDate] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => setDate({
            date: toWeekdayDate(new Date(), locale),
            time: new Date().toLocaleTimeString()
        }), 1000);

        return () => clearInterval(timer);
    }, []);

    return date;
};

export { useDate };
