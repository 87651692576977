import { useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";

const ContentHeader = props => {
    const { translate } = useTranslation();

    return (
        <h5 className="heading">{translate(props.headline)}</h5>
    );
};

ContentHeader.propTypes = {
    headline: PropTypes.string.isRequired
};

export { ContentHeader };
