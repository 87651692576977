export const sort = ({ items, property, ordering = "asc", getter = x => x }) => {
    if(!items) return [];

    const order    = ordering.toLowerCase();
    const newItems = items.concat([]);

    newItems.sort((a, b) => {
        const itemA = getter(a[property]);
        const itemB = getter(b[property]);

        if(order === "asc") return itemA < itemB ? -1 : 1;

        return itemB < itemA ? -1 : 1;
    });

    return newItems;
};
