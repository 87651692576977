import { useBranch } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Menu } from "./Menu";
import { Content } from "./Content";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Root as CompRoot, rootClassName } from "@circle/kip-components";
import { SidebarHost } from "@circle/kip-components";
import { Router } from "./Router";

const Root = ({ ...props }) => {
    const { init, history } = useBranch({
        init:    ["init"],
        history: ["history"]
    });

    useEffect(() => {
        document.getElementById("default-loading-anchor")?.remove();
    }, []);

    return (
        <Router history={history} id={props?.id}>
            <DndProvider backend={HTML5Backend}>
                {
                    init &&
                    <CompRoot>
                        <div className={`flex ${rootClassName}`}>
                            <SidebarHost>
                                <Menu/>
                                <Content />
                            </SidebarHost>
                        </div>
                    </CompRoot>
                }
            </DndProvider>
        </Router>
    );
};

Root.propTypes = {
    id: PropTypes.string
};

export { Root };
