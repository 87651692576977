import { useState } from "react";
import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { TimespanDropdownToggle } from "./TimespanDropdownToggle";
import { defaultTimeSpans, msToString } from "../../helper/messageDeleteTimeSpans";
import { Icon, Button, Modal } from "@circle/kip-components";
import { resolveClassNames } from "palstek";

import styles from "./settingsConfi.module.scss";

const SettingsConfig = () => {
    const { trigger }           = useTrigger();
    const { translate }         = useTranslation();
    const [modal, setModal]     = useState(false);
    const [content, setContent] = useState({
        errAfterMS:    defaultTimeSpans.never.multiplier,
        taskAfterMS:   defaultTimeSpans.never.multiplier,
        warnAfterMS:   defaultTimeSpans.never.multiplier,
        infoAfterMS:   defaultTimeSpans["30d"].multiplier,
        systemAfterMS: defaultTimeSpans.never.multiplier,
        logAfterMS:    defaultTimeSpans["7d"].multiplier
    });
    const { selectedPlants, plants } = useBranch({
        selectedPlants: ["settingsPlantsSelection"],
        plants:         ["plants"]
    });

    const apply = () => {
        const newSettings = selectedPlants.map(plant => ({ ...content, plant_id: plant })); // eslint-disable-line

        trigger("applyDeleteSettings", newSettings);
        trigger("removePlantFromSettingsSelection", "all");
    };

    const onApplyAllConfirm = () => {
        setModal(false);

        const newSettings = plants.map(plant => ({
            ...content,
            plant_id: plant.id // eslint-disable-line camelcase
        }));

        trigger("applyDeleteSettings", newSettings);
        trigger("removePlantFromSettingsSelection", "all");
    };

    return (
        <div className="config">
            <div className="settings-main flex">
                <div className={resolveClassNames(styles.settings_grid, styles.mt_4)}>
                    <Icon _icon="Error" className="error-icon"/>
                    <div className="vertical-align">
                        <span>{translate("settings.error")}</span>
                    </div>
                    <div className="settings-toggle">
                        <TimespanDropdownToggle
                            key="errAfterMS"
                            defaultValue={msToString(content.errAfterMS)}
                            onChange={x => setContent({ ...content, errAfterMS: parseInt(x, 10) })}
                        />
                    </div>
                    <Icon _icon="Task" className="task-icon"/>
                    <div className="vertical-align">
                        <span>{translate("settings.task")}</span>
                    </div>
                    <div className="settings-toggle">
                        <TimespanDropdownToggle
                            key="taskAfterMS"
                            defaultValue={msToString(content.taskAfterMS)}
                            onChange={x => setContent({ ...content, taskAfterMS: parseInt(x, 10) })}
                        />
                    </div>
                    <Icon _icon="Warning" className="warning-icon"/>
                    <div className="vertical-align">
                        <span>{translate("settings.warning")}</span>
                    </div>
                    <div className="settings-toggle">
                        <TimespanDropdownToggle
                            key="warnAfterMS"
                            defaultValue={msToString(content.warnAfterMS)}
                            onChange={x => setContent({ ...content, warnAfterMS: parseInt(x, 10) })}
                        />
                    </div>
                    <Icon _icon="Info" className="info-icon"/>
                    <div className="vertical-align">
                        <span>{translate("settings.info")}</span>
                    </div>
                    <div className="settings-toggle">
                        <TimespanDropdownToggle
                            key="infoAfterMS"
                            defaultValue={msToString(content.infoAfterMS)}
                            onChange={x => setContent({ ...content, infoAfterMS: parseInt(x, 10) })}
                        />
                    </div>
                    <Icon _icon="System" className="system-icon"/>
                    <div className="vertical-align">
                        <span>{translate("settings.system")}</span>
                    </div>
                    <div className="settings-toggle">
                        <TimespanDropdownToggle
                            key="systemAfterMS"
                            defaultValue={msToString(content.systemAfterMS)}
                            onChange={x => setContent({ ...content, systemAfterMS: parseInt(x, 10) })}
                        />
                    </div>
                    <Icon _icon="Log" className="logs-icon"/>
                    <div className="vertical-align">
                        <span>{translate("settings.logs")}</span>
                    </div>
                    <div className="settings-toggle">
                        <TimespanDropdownToggle
                            key="logAfterMS"
                            defaultValue={msToString(content.logAfterMS)}
                            onChange={x => setContent({ ...content, logAfterMS: parseInt(x, 10) })}
                        />
                    </div>
                </div>
                <div className="flex-column-reverse">
                    <div className="mt-2">
                        <Button onClick={() => setModal(true)}>
                            { translate("settings.select.all") }
                        </Button>
                    </div>
                    <Button _appearance="primary" onClick={() => apply(selectedPlants)} disabled={selectedPlants.length === 0}>
                        { translate("settings.apply") }
                    </Button>
                </div>
                <Modal
                    title={translate("modal.applyAll.danger")}
                    isOpen={modal}
                    isCloseable={false}
                    onClose={() => setModal(false)}
                >
                    <div className="flex-column">
                        <span className="font-bold mt-3 center">{ translate("modal.applyAll.really") }</span>
                        <div className="flex mt-10 justify-end">
                            <Button onClick={() => setModal(false)}>
                                { translate("actions.cancel") }
                            </Button>
                            <Button _appearance="primary" onClick={onApplyAllConfirm}>
                                { translate("actions.confirm") }
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export { SettingsConfig };
