import { hasPermission, useBranch, useTranslation } from "@circle/gestalt-app";
import styles from "./messagesStatisticCard.module.scss";
import { getRangeString } from "../../../helper/date";
import names from "../../../enums/names";
import { resolveClassNames } from "palstek";
import { toMinuteString } from "../../../helper/helper";

const MessagesStatisticCard = () => {
    const { translate, currentLanguage }                     = useTranslation();
    const { options, statistic, selectedMessageTypes, user } = useBranch({
        options:              ["queryOptions"],
        statistic:            ["monitorStatistic"],
        selectedMessageTypes: ["queryOptions", "messageTypes"],
        user:                 ["user"]
    });

    const canShowLogs = hasPermission(user, "MESSAGE_MONITOR_SHOW_LOGS");

    return (
        <div className={styles.statisticMessages}>
            {
                statistic.length === 0 &&
                <div className={styles.empty}>{translate("datatable.empty")}</div>
            }
            {
                statistic.length > 0 &&
                <>
                    <div className={styles.statisticHeader}>
                        <h5>
                            { options.range === "shift" ? translate("overview.selector.shift") : getRangeString([options.calendar.from, options.calendar.until], currentLanguage)}
                            <span className={styles.subHeader} />
                        </h5>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th />
                                <th><small className={styles.tableHeaders}>{translate("message.statistic.table.duration")}</small></th>
                                <th><small className={styles.tableHeaders}>{translate("message.statistic.table.frequency")}</small></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ["err", "task", "warn", "info", "system", "log"]
                                .map(x => statistic.find(y => y.type === x))
                                .filter(x => (x.type === "logs" ? canShowLogs : selectedMessageTypes.includes(x.type)))
                                .map((x, key) => (
                                    <tr key={key}>
                                        <td><b className={styles.type}>{translate(`overview.type.${x.type}`)}</b> <span className={resolveClassNames(names[x.type], styles.typeIcon)}>&#x25CF;</span></td>
                                        <td><b>{x.totalDuration || x.duration ? toMinuteString(x.totalDuration || x.duration).replace(" min", "") : "-"}</b></td>
                                        <td><b>{x.amount ? x.amount : "-"}</b></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </>
            }
        </div>
    );
};

export { MessagesStatisticCard };
