interface Format {
    year: "numeric",
    month: "long" | "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
}

const convertToUTC = (date: Date) => {
    return new Date(date.toUTCString().slice(0, -4));
};

const toWeekdayDate = (date: Date, locale = "en") => {
    return date.toLocaleDateString(locale, { weekday: "short", day: "2-digit", year: "numeric", month: "2-digit" }).replace(",", "");
};

// eslint-disable-next-line complexity
const formatTimeSpan = (from: Date, until: Date, locale: string) => {
    const yearsDiffer = until.getUTCFullYear() - from.getUTCFullYear() > 0;
    const monthsDiffer = yearsDiffer || until.getUTCMonth() - from.getUTCMonth() > 0;
    const daysDiffer = monthsDiffer || until.getUTCDate() - from.getUTCDate() > 0;

    const hoursDiffer   = until.getUTCHours() - from.getUTCHours() > 0;
    const minutesDiffer = hoursDiffer || until.getUTCMinutes() - from.getUTCMinutes() > 0;
    const secondsDiffer = minutesDiffer || until.getUTCSeconds() - from.getUTCSeconds() > 0;

    const dateOptions = {
        ...(yearsDiffer ? { year: "numeric" } : {}),
        ...(monthsDiffer ? { month: "2-digit" } : {}),
        ...(daysDiffer ? { day: "2-digit" } : {})
    } as Format;

    const timeOptions = {
        hour: "numeric",
        ...(minutesDiffer ? { minute: "numeric" } : {}),
        ...(secondsDiffer ? { second: "numeric" } : {})
    } as Format;

    const summaryOptions = {
        ...(!yearsDiffer ? { year: "numeric" } : {}),
        ...(!monthsDiffer ? { month: "long" } : {}),
        ...(!daysDiffer ? { day: "2-digit" } : {})
    } as Format;

    const canHideTime = (from.getUTCHours() === 0 && from.getUTCMinutes() === 0 && until.getUTCHours() === 23 && until.getUTCMinutes() === 59) && !yearsDiffer;

    const dateSummary = !(yearsDiffer && monthsDiffer && daysDiffer) ? ` ${convertToUTC(until).toLocaleString(locale, summaryOptions)}` : "";

    const date = Object.keys(dateOptions).length === 0 ? dateSummary : `${convertToUTC(from).toLocaleString(locale, dateOptions)} - ${convertToUTC(until).toLocaleString(locale, dateOptions)}${dateSummary}`;
    const time =  (date === dateSummary && !canHideTime) ? ` ${convertToUTC(from).toLocaleString(locale, timeOptions)} - ${convertToUTC(until).toLocaleString(locale, timeOptions)}` : "";

    return `${date}${time}`;
};

export {
    formatTimeSpan,
    toWeekdayDate
};
