import PropTypes from "prop-types";
import { useTranslation, useTrigger } from "@circle/gestalt-app";
import { Card, CardHeader, Icon, OverflowContainer, SwitchButton } from "@circle/kip-components";
import { resolveClassNames } from "palstek";

import styles from "./settingsTile.module.scss";

const SettingsToggleTile = ({ ...props }) => {
    const { trigger }   = useTrigger();
    const { translate } = useTranslation();

    const handleToggleClick = (value, messageType) => {
        trigger("toggleReceiveSetting", {
            id:              props.id,
            plant_id:        props.plant_id, // eslint-disable-line camelcase
            isInfoAllowed:   messageType === "info" ? value : props.isInfoAllowed,
            isSystemAllowed: messageType === "system" ? value : props.isSystemAllowed,
            isLogAllowed:    messageType === "log" ? value : props.isLogAllowed
        });
    };

    return (
        <div className={resolveClassNames(styles.item, styles.toggleItem)}>
            <Card>
                <CardHeader className={styles.header}>
                    <img src={props.plant.image ?? "/images/Bitmap.png"} loading="lazy" alt="Manufacturer Logo" />
                    <div className={styles.titles}>
                        <h2>
                            {
                                (translate(props.plant.name) || "").length > 24 ?
                                    `${translate(props.plant.name).substring(0, 24)}...` :
                                    translate(props.plant.name)
                            }
                        </h2>
                        <h3>
                            {translate(props.plant.location)}
                        </h3>
                    </div>
                </CardHeader>
                <OverflowContainer className={resolveClassNames(styles.content, styles.toggleContent)}>
                    <div className={styles.label}>
                        <Icon _icon="Info" className="info-icon" />
                        <span>{translate("settings.text.toggle.infos")}</span>
                        <div className={styles.switchButtonContainer}>
                            <SwitchButton
                                className={styles.switchButton}
                                options={[
                                    { value: "on", label: translate("settings.text.toggleOn"), checked: props.isInfoAllowed },
                                    { value: "off", label: translate("settings.text.toggleOff"), checked: !props.isInfoAllowed }
                                ]}
                                onChange={x => handleToggleClick(x.target.value === "on", "info")}
                            />
                        </div>
                    </div>
                    <div className={styles.label}>
                        <Icon _icon="System" className="system-icon" />
                        <span>{translate("settings.text.toggle.system")}</span>
                        <div className={styles.switchButtonContainer}>
                            <SwitchButton
                                className={styles.switchButton}
                                options={[
                                    { value: "on", label: translate("settings.text.toggleOn"), checked: props.isSystemAllowed },
                                    { value: "off", label: translate("settings.text.toggleOff"), checked: !props.isSystemAllowed }
                                ]}
                                onChange={x => handleToggleClick(x.target.value === "on", "system")}
                            />
                        </div>
                    </div>
                    <div className={styles.label}>
                        <Icon _icon="Log" className="logs-icon" />
                        <span>{translate("settings.text.toggle.logs")}</span>
                        <div className={styles.switchButtonContainer}>
                            <SwitchButton
                                className={styles.switchButton}
                                options={[
                                    { value: "on", label: translate("settings.text.toggleOn"), checked: props.isLogAllowed },
                                    { value: "off", label: translate("settings.text.toggleOff"), checked: !props.isLogAllowed }
                                ]}
                                onChange={x => handleToggleClick(x.target.value === "on", "log")}
                            />
                        </div>
                    </div>
                </OverflowContainer>
            </Card>
        </div>
    );
};

SettingsToggleTile.propTypes = {
    plant:           PropTypes.object.isRequired,
    id:              PropTypes.number,
    plant_id:        PropTypes.string, // eslint-disable-line camelcase
    isInfoAllowed:   PropTypes.bool,
    isSystemAllowed: PropTypes.bool,
    isLogAllowed:    PropTypes.bool
};

export { SettingsToggleTile };
