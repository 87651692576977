import { Routes, Route, Navigate } from "react-router-dom";
import { AdminRouter } from "./admin/AdminRouter";
import { OverviewRouter } from "./overview/OverviewRouter";
import { MonitorRouter } from "./monitor/MonitorRouter";
import { EvaluationRouter } from "./evaluation/EvaluationRouter";
import { ObservationRouter } from "./observation/ObservationRouter";
import { CausesOverviewRouter } from "./cause/CausesOverviewRouter";

const Content = () => {
    return (
        <div id="page-content" className="page-content flex-grow">
            <Routes>
                <Route path="/overview/*" element={<OverviewRouter/>}/>
                <Route path="/causes/*" element={<CausesOverviewRouter/>}/>
                <Route path="/causes/:plantId/*" element={<CausesOverviewRouter/>}/>
                <Route path="/monitor/*" element={<MonitorRouter/>}/>
                <Route path="/monitor/:plantId/*" element={<MonitorRouter/>}/>
                <Route path="/admin" element={<AdminRouter/>}/>
                <Route path="/evaluation/*" element={<EvaluationRouter/>}/>
                <Route path="/evaluation/:plantId/*" element={<EvaluationRouter/>}/>
                <Route path="/observation/:plantId/*" element={<ObservationRouter/>}/>
                <Route path="*" element={<Navigate to="/overview" replace/>}/>
            </Routes>
        </div>
    );
};

export { Content };
