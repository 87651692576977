import { hasPermission, useBranch, useTrigger } from "@circle/gestalt-app";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, ComposedChart } from "recharts";
import { Tooltip as CustomTooltip } from "../Tooltip";
import { ActionFooter } from "./actionFooter";
import PropTypes from "prop-types";


import styles from "./mainGraph.module.scss";
import { useEffect, useState } from "react";
import { resolveClassNames } from "palstek";

const MainGraph = ({ ...props }) => { // eslint-disable-line complexity
    const { trigger } = useTrigger();
    const { selectedMessageTypes, user, mainGraphViewTypes, graphMessages, options } = useBranch({
        selectedMessageTypes: ["queryOptions", "messageTypes"],
        mainGraphViewTypes:   ["queryOptions", "mainGraphViewTypes"],
        user:                 ["user"],
        graphMessages:        ["graphMessages"],
        options:              ["queryOptions"]
    });
    const [disabled, setDisabled] = useState(false);

    const onClick = e => {
        if(!e.activePayload) return;

        const range =  e.activePayload[1].payload.timestamp[1] - e.activePayload[0].payload.timestamp[0];

        if(range <= 3600000 || !e || !e.activePayload || !e.activePayload[0] || !e.activePayload[0].payload) setDisabled(true);

        const selector = e.activePayload[0].payload.timestamp ?
            { from: new Date(e.activePayload[0].payload.timestamp[0]), until: new Date(e.activePayload[1].payload.timestamp[1] - 1) } :
            null;

        if(!selector) return;

        props.onGraphZoomIn(selector);

        trigger("applyOption", "calendar", selector);
    };

    const onViewChange = type => {
        const filteredTypes = mainGraphViewTypes.includes(type) ? mainGraphViewTypes.filter(item => item !== type) : mainGraphViewTypes.concat(type);

        trigger("onMainViewChange", filteredTypes);
    };

    useEffect(() => {
        const fromTimestampInSeconds = options.calendar.from.getTime() / 1000;
        const untilTimestampInSeconds = options.calendar.until.getTime() / 1000;

        const timeDifferenceInSeconds = untilTimestampInSeconds - fromTimestampInSeconds;

        if(timeDifferenceInSeconds >= 3600) {
            setDisabled(false);
            return;
        }
        setDisabled(true);

        return;
    }, [options]);

    useEffect(() => {
        const messageTypesToCheck = ["warn", "info", "system", "log"];

        if(selectedMessageTypes.some(type => messageTypesToCheck.includes(type))) return trigger("onMainViewChange", ["duration", "frequency"]);

        return trigger("onMainViewChange", ["duration"]);
    }, [selectedMessageTypes]);

    return (
        <div className={!disabled ? styles.mainGraph : resolveClassNames(styles.mainGraph, styles.disabled)}>
            <ResponsiveContainer width="100%" height={280}>
                <ComposedChart
                    onClick={onClick}
                    width={500}
                    height={100}
                    data={graphMessages}
                    margin={{
                        top:    15,
                        right:  0,
                        bottom: 0,
                        left:   15
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" tickFormatter={value => `${Math.floor(value / 60)} min`} domain={[0, 60 * 60]}/>
                    <YAxis yAxisId="right" orientation="right" allowDecimals={false}/>
                    <Tooltip content={<CustomTooltip isFrequency={mainGraphViewTypes?.includes("frequency")} selectedMessageTypes={selectedMessageTypes}/>} />

                    {mainGraphViewTypes?.includes("duration") &&
                        <>

                            {
                                selectedMessageTypes.includes("err") &&
                                <Bar yAxisId="left" dataKey="errorDuration" fill={mainGraphViewTypes.includes("frequency") ? "rgba(235, 96, 96, 0.5)" : "#EB6060"} />
                            }
                            {
                                selectedMessageTypes.includes("task") &&
                                <Bar yAxisId="left" dataKey="taskDuration" fill={mainGraphViewTypes.includes("frequency") ? "rgba(255, 153, 102, 0.5)" : "#FF9966"} />
                            }
                            {
                                selectedMessageTypes.includes("warn") &&
                                <Bar yAxisId="left" dataKey="warningDuration" fill={mainGraphViewTypes.includes("frequency") ? "rgba(242, 208, 56, 0.5)" : "#F7E463"} />
                            }
                        </>
                    }

                    {mainGraphViewTypes?.includes("frequency") &&
                        <>
                            {
                                selectedMessageTypes.includes("err") &&
                                <Line yAxisId="right" dot={false} type="monotone" dataKey="errors" strokeWidth={3} stroke="#EB6060" />
                            }
                            {
                                selectedMessageTypes.includes("task") &&
                                <Line yAxisId="right" dot={false} type="monotone" dataKey="tasks" strokeWidth={3} stroke="#FF9966" />
                            }
                            {
                                selectedMessageTypes.includes("warn") &&
                                <Line yAxisId="right" dot={false} type="monotone" dataKey="warnings" strokeWidth={3} stroke="#F7E463" />
                            }
                            {
                                selectedMessageTypes.includes("info") &&
                                <Line yAxisId="right" dot={false} type="monotone" dataKey="infos" strokeWidth={3} stroke="#7DA7D9" />
                            }
                            {
                                selectedMessageTypes.includes("system") &&
                                <Line yAxisId="right" dot={false} type="monotone" dataKey="systems" strokeWidth={3} stroke="#4F5358" />
                            }
                            {
                                selectedMessageTypes.includes("log") && hasPermission(user, "MESSAGE_MONITOR_SHOW_LOGS") &&
                                <Line yAxisId="right" dot={false} type="monotone" dataKey="logs" strokeWidth={3} stroke="#A8A8A8" />
                            }
                        </>
                    }

                </ComposedChart>
            </ResponsiveContainer>
            <ActionFooter disabled={disabled} isDuration={mainGraphViewTypes?.includes("duration")} isFrequency={mainGraphViewTypes?.includes("frequency")} onViewChange={onViewChange} />
        </div>
    );
};

MainGraph.propTypes = {
    onGraphZoomIn: PropTypes.func
};

export { MainGraph };
