import PropTypes from "prop-types";
import { toMinuteString } from "../helper/helper";

const Tooltip = ({ active = false, label, payload, formatter = x => x }) => {
    const formatValue = ele => {
        if(ele.dataKey === "duration")
            return toMinuteString(ele.value);

        return formatter(ele.value);
    };

    return (
        <>
            {
                active &&
                <div className="custom-tooltip">
                    <p className="tooltip-label">{`${label}`}</p>
                    {
                    payload?.map((ele, key) => (
                        <p key={key} className="tooltip-block">
                            <span className="tooltip-icon"> {ele.name}: </span>
                            {ele.value ? formatValue(ele) : ""}
                        </p>
                    ))
                }
                </div>
            }
        </>
    );
};

Tooltip.propTypes = {
    active:    PropTypes.bool,
    payload:   PropTypes.arrayOf(PropTypes.object).isRequired,
    formatter: PropTypes.func,
    label:     PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
};

export { Tooltip };
