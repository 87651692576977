const defaultTimeSpans = {
    "never": {
        translation: "settings.never",
        multiplier:  -1
    },
    "24h": {
        translation: "settings.24h",
        multiplier:  1000 * 60 * 60 * 24
    },
    "7d": {
        translation: "settings.7d",
        multiplier:  1000 * 60 * 60 * 24 * 7
    },
    "30d": {
        translation: "settings.30d",
        multiplier:  1000 * 60 * 60 * 24 * 30
    },
    "6M": {
        translation: "settings.6M",
        multiplier:  1000 * 60 * 60 * 24 * 183
    },
    "1Y": {
        translation: "settings.1Y",
        multiplier:  1000 * 60 * 60 * 24 * 365
    },
    "custom": {
        translation: "settings.custom",
        multiplier:  null
    }
};

const timeUnits = {
    hour: {
        translation: "custom.hour",
        multiplier:  1000 * 60 * 60
    },
    day: {
        translation: "custom.day",
        multiplier:  1000 * 60 * 60 * 24
    },
    month: {
        translation: "custom.month",
        multiplier:  1000 * 60 * 60 * 24 * 30
    },
    year: {
        translation: "custom.year",
        multiplier:  1000 * 60 * 60 * 24 * 365
    }
};

// eslint-disable-next-line complexity
const customMsToString = (_ms: number | string | undefined | null, translate: (x: string) => string) => {
    // eslint-disable-next-line no-undefined
    if(_ms === undefined || _ms === null)
        return "---";

    const ms = parseInt(`${_ms}`, 10);
    const localeDictionary = {
        min:    "min",
        hours:  "h",
        days:   "d",
        months: "m",
        years:  "y"
    };

    if((ms < timeUnits.day.multiplier || ms % timeUnits.day.multiplier !== 0) && ms % timeUnits.year.multiplier !== 0 && ms % timeUnits.month.multiplier !== 0)
        return `${Math.floor(ms / timeUnits.hour.multiplier)} ${translate ? translate(timeUnits.hour.translation) : localeDictionary.hours}`;
    if((ms < timeUnits.month.multiplier || ms % timeUnits.month.multiplier !== 0) && ms % timeUnits.year.multiplier !== 0)
        return `${Math.floor(ms / timeUnits.day.multiplier)} ${translate ? translate(timeUnits.day.translation) : localeDictionary.days}`;
    if(ms < timeUnits.year.multiplier || ms % timeUnits.year.multiplier !== 0)
        return `${Math.floor(ms / timeUnits.month.multiplier)} ${translate ? translate(timeUnits.month.translation) : localeDictionary.months}`;
    if(ms > timeUnits.year.multiplier)
        return `${Math.floor(ms / timeUnits.year.multiplier)} ${translate ? translate(timeUnits.year.translation) : localeDictionary.years}`;
    return `${parseFloat(`${ms / 1000 / 60 / 60}`).toFixed(2)}h`;
};

// eslint-disable-next-line complexity
const msToString = (_ms: number | string | undefined | null, friendly = false, translate: (x: string) => string = x => x) => {
    // eslint-disable-next-line no-undefined
    if(_ms === undefined || _ms === null)
        return "---";

    const ms = parseInt(`${_ms}`, 10);

    if(ms === defaultTimeSpans["24h"].multiplier)
        return !translate ? "24h" : translate(defaultTimeSpans["24h"].translation);

    if(ms === defaultTimeSpans["30d"].multiplier)
        return !translate ? "30d" : translate(defaultTimeSpans["30d"].translation);

    if(ms === defaultTimeSpans["6M"].multiplier)
        return !translate ? "6M" : translate(defaultTimeSpans["6M"].translation);

    if(ms === defaultTimeSpans["1Y"].multiplier)
        return !translate ? "1Y" : translate(defaultTimeSpans["1Y"].translation);
    if(ms === defaultTimeSpans.never.multiplier)
        return !translate ? "never" : translate(defaultTimeSpans.never.translation);

    // eslint-disable-next-line no-nested-ternary
    return friendly ? customMsToString(ms, translate) : !translate ? "custom" : defaultTimeSpans.custom.translation;
};

interface Setting {
    errAfterMS: string,
    taskAfterMS: string,
    warnAfterMS: string,
    infoAfterMS: string,
    systemAfterMS: string,
    logAfterMS: string
}


const parseMessageDeleteSettings = (settings: Setting[]) => {
    const _transform = (inp: Setting) => {
        return {
            errAfterMS:    parseInt(inp.errAfterMS, 10),
            taskAfterMS:   parseInt(inp.taskAfterMS, 10),
            warnAfterMS:   parseInt(inp.warnAfterMS, 10),
            infoAfterMS:   parseInt(inp.infoAfterMS, 10),
            systemAfterMS: parseInt(inp.systemAfterMS, 10),
            logAfterMS:    parseInt(inp.logAfterMS, 10)
        };
    };

    if(!(settings instanceof Array)) return _transform(settings);

    return [...settings].map(setting => _transform(setting));
};

export {
    defaultTimeSpans,
    timeUnits,
    msToString,
    parseMessageDeleteSettings
};
