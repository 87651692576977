import PropTypes from "prop-types";

const Checkbox = ({ disabled = false, className = "", value = "", checked = false, onChange = x => x, ...props }) => {
    return (
        <label className={`${className} ${!disabled ? "clickable" : "checkbox-disable"} checkbox-label`}>
            <input disabled={disabled} type="checkbox" className="checkbox-input" value={value} checked={checked} onChange={e => onChange(e)} name={props.name} />
            <span className="checkbox-indicator" />
            {props.children}
        </label>
    );
};

Checkbox.propTypes = {
    className: PropTypes.string,
    onChange:  PropTypes.func,
    checked:   PropTypes.bool,
    name:      PropTypes.string,
    value:     PropTypes.string,
    children:  PropTypes.node,
    disabled:  PropTypes.bool
};

export { Checkbox };
