import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { BusyIndicator } from "./BusyIndicator";
import { hasPermission, useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { Icon, Sidebar, SidebarButton } from "@circle/kip-components";
import { sidebarButtonHighlightClassName } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import styles from "./menu.module.scss";

const Menu = () => { // eslint-disable-line complexity
    const { trigger }   = useTrigger();
    const location      = useLocation();
    const { translate } = useTranslation();
    const { globalBusy, user, selectedPlant, observations, options } = useBranch({
        globalBusy:    ["globalBusyCounter"],
        user:          ["user"],
        selectedPlant: ["selectedPlant"],
        observations:  ["observations"],
        options:       ["queryOptions"]
    });

    const showObservation = selectedPlant && observations.find(x => x.plant_id === selectedPlant.id);

    const onTimeframeSelection = () => {
        if(options.range === "today" || options.range === "yesterday" || (options.selected?.until - options.selected?.from) <= 86400000) {
            trigger("applyOption", "timeframes", "shifts");
            return;
        }
        trigger("applyOption", "timeframes", "all");
        return;
    };

    return (
        <Sidebar className={styles.sidebar}>
            <Routes>
                <Route
                    path="/overview/:plantId"
                    element={
                        <div id="overview-menu" className={resolveClassNames(sidebarButtonHighlightClassName, styles.menuItem)}>
                            <SidebarButton className="flex-column">
                                <Icon className={selectedPlant ? "menu-icon combined-icon" : "menu-icon"} _icon="Overview"/>
                                {selectedPlant && <Icon className="menu-icon combined-icon" _icon="Analysis"/>}
                                <Tooltip anchorSelect="#overview-menu" effect="solid" place="right" content={translate("menu.overview.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }} />
                            </SidebarButton>
                        </div>
                    }
                />
                <Route
                    path="*"
                    element={
                        <NavLink id="overview-menu" to={selectedPlant?.id ? `/overview/${selectedPlant.id}${location.search}` : `/overview${location.search}`} className={({ isActive }) => resolveClassNames((isActive || location.pathname.startsWith("/monitor")) && sidebarButtonHighlightClassName, styles.menuItem)} rel="noopener noreferrer" onClick={() => trigger("reset")}>
                            <SidebarButton onClick={() => onTimeframeSelection()} className="flex-column">
                                <Icon className={selectedPlant ? "menu-icon combined-icon" : "menu-icon"} _icon="Overview"/>
                                {selectedPlant && <Icon className="menu-icon combined-icon" _icon="Analysis"/>}
                                <Tooltip anchorSelect="#overview-menu" effect="solid" place="right" content={translate("menu.overview.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }} />
                            </SidebarButton>
                        </NavLink>
                    }
                />
            </Routes>
            { showObservation &&
                <NavLink id="observation-view" to={`/observation/${selectedPlant.id}`} className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} rel="noopener noreferrer" onClick={e => location.pathname.startsWith("/observation") ? e.preventDefault() : trigger("reset")}>
                    <SidebarButton>
                        <Icon className="menu-icon" _icon="Star"/>
                        <Tooltip anchorSelect="#observation-view" effect="solid" place="right" content={translate("menu.observation.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }} />
                    </SidebarButton>
                </NavLink>
            }
            {selectedPlant &&
            <NavLink id="cause-view" to="/causes" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} rel="noopener noreferrer" onClick={e => location.pathname.startsWith("/causes") ? e.preventDefault() : trigger("reset")}>
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Causes"/>
                    <Tooltip anchorSelect="#cause-view" effect="solid" place="right" content={translate("menu.causes.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }} />
                </SidebarButton>
            </NavLink>
            }
            {
                hasPermission(user, "MESSAGE_MONITOR_ADMIN") &&
                <NavLink id="admin-view" to="/admin" rel="noopener noreferrer" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} onClick={e => location.pathname === "/admin" ? e.preventDefault() : trigger("reset")}>
                    <SidebarButton>
                        <Icon className="menu-icon" _icon="Settings"/>
                        <Tooltip anchorSelect="#admin-view" effect="solid" place="right" content={translate("menu.settings.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }} />
                    </SidebarButton>
                </NavLink>
            }
            {
                globalBusy > 0 &&
                <BusyIndicator withContainer />
            }
        </Sidebar>
    );
};

export { Menu };
