import { useBranch, useTranslation } from "@circle/gestalt-app";
import { useDate } from "../../hooks/date";

const CauseHeader = () => {
    const { currentLanguage } = useTranslation();
    const date     = useDate(currentLanguage);
    const { user } = useBranch({
        user: ["user"]
    });

    return (
        <div className="overview-item-heading flex-column flex-grow pd-20">
            <div className="text-block-primary flex-row-reverse flex-grow">
                <span>{date.date}{" "}{date.time}</span>
            </div>
            <div className="text-block-secondary flex-row-reverse flex-grow">
                <span>{user.name}</span>
            </div>
        </div>
    );
};

export { CauseHeader };
